<template>
  <FilterBlock :showFilter="showFilter" :title="$t('common_filterBy.localization_value.value')" @close="closeFilter" @changeFilter="changeFilter">
    <template slot="body">
      <div class="table-filter__item"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <span class="admin-edit" @click="editTranslate(['fbm_id'])"></span>
        <DefaultInput
            :label="$t('fbm_id.localization_value.value')"
            :type="'text'"
            v-model="id"
        />
      </div>
      <div class="table-filter__item"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <span class="admin-edit" @click="editTranslate(['fbm_Created'])"></span>
        <DatePickerDefault
            :label="$t('fbm_Created.localization_value.value')"
            v-model="date"
        >
          <template slot="body">
            <date-picker
                v-model="date"
                ref="datePicker"
                valueType="format"
                range
                :lang="$store.getters.getDatePickerTranslations[$store.getters.GET_LANG]"
                :format="'MM/DD/YY'"
                :placeholder="'mm/dd/yy'"
            ></date-picker>
          </template>
        </DatePickerDefault>
      </div>

      <div class="table-filter__item"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
<!--        <span class="admin-edit" @click="editTranslate(['consolidationOrders_shipped'])"></span>-->
        <DatePickerDefault
            :label="$t('consolidationOrders_shipped.localization_value.value')"
            v-model="ship"
        >
          <template slot="body">
            <date-picker
                v-model="ship"
                ref="datePicker"
                valueType="format"
                range
                :lang="$store.getters.getDatePickerTranslations[$store.getters.GET_LANG]"
                :format="'MM/DD/YY'"
                :placeholder="'mm/dd/yy'"
            ></date-picker>
          </template>
        </DatePickerDefault>
      </div>

      <div class="table-filter__item"
           v-if="isAdmin"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <span class="admin-edit" @click="editTranslate(['shop_user'])"></span>
        <SearchSelect
            :options="options"
            :label="$t('shop_user.localization_value.value')"
            :optionsLabel="'email.contacts[0]'"
            :functionSearch="functionSearchUserFilter"
            @change="changeUsersFilter"
            :selected="userName"
            :typeSelect="'users'"
            :userEmail="true"
        />
      </div>

      <div class="table-filter__item"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <span class="admin-edit" @click="editTranslate(['fbm_Recepient'])"></span>
        <DefaultInput
            :label="$t('fbm_Recepient.localization_value.value')"
            :type="'text'"
            v-model="recipient"
        />
      </div>

      <div class="table-filter__item"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <span class="admin-edit" @click="editTranslate(['fbm_TrackingNumber'])"></span>
        <DefaultInput
            :label="$t('fbm_TrackingNumber.localization_value.value')"
            :type="'text'"
            v-model="trackingNumber"
        />
      </div>

    </template>
    <template slot="footer">
      <MainButton @click.native="resetFilter" :value="$t('common_resetFilters.localization_value.value')"  class="secondary wfc"/>
      <MainButton @click.native="changeFilter" :value="$t('common_applyFilters.localization_value.value')" class="wfc"/>
    </template>
  </FilterBlock>
</template>

<script>
import MainButton from "@/components/UI/buttons/MainButton/MainButton";
import DefaultInput from "@/components/UI/inputs/DefaultInput/DefaultInput";
import FilterBlock from "@/components/coreComponents/FilterBlock/FilterBlock";
import {filterFunc} from "@/mixins/filterFuncMixin/filterFunc";
import {usersFunc} from "@/mixins/usersMixins/usersFunc";
import {queryFilterMixin} from "@/mixins/routeFilterMixins/queryFilterMixin";
import DatePickerDefault from "@/components/UI/inputs/DatePickerDefault/DatePickerDefault";
import SearchSelect from "@/components/UI/selectiones/SearchSelect/SearchSelect";
import DatePicker from "vue2-datepicker";
export default {
  name: "EasyOrdersTableFilter",
  components: {
    SearchSelect,
    DatePickerDefault,
    FilterBlock,
    DefaultInput,
    MainButton,
    DatePicker,
  },

  mixins: [filterFunc, usersFunc, queryFilterMixin],

  props: {
    navTabs: Object,
    showFilter: Boolean,
    filterGetParams: Object,
  },

  data(){
    return {

      date: this.filterGetParams.date ? this.generateFilterDate(this.filterGetParams.date, 'MM/DD/YY'): '',
      ship: this.filterGetParams.ship ? this.generateFilterDate(this.filterGetParams.ship, 'MM/DD/YY'): '',
      id: this.filterGetParams.id ? this.filterGetParams.id : '',
      recipient: this.filterGetParams.recipient ? this.filterGetParams.recipient : '',
      trackingNumber: this.filterGetParams.trackingNumber ? this.filterGetParams.trackingNumber : '',

      userName: this.filterGetParams.userName ? this.filterGetParams.userName : '',
      userId: this.filterGetParams.userId ? this.filterGetParams.userId : '',

      filterCounts: [
        'date',
        'id',
        'recipient',
        'ship',
        'trackingNumber',
        'userName',
        'userId',
      ],

      options:[],

    }
  },

  watch: {
    filterGetParams(newVal) {
      this.date = newVal.date ? this.generateFilterDate(newVal.date, 'MM/DD/YY') : ''
      this.ship = newVal.ship ? this.generateFilterDate(newVal.ship, 'MM/DD/YY') : ''
      this.id = newVal.id ? newVal.id : ''
      this.recipient = newVal.recipient ? newVal.recipient : ''
      this.trackingNumber = newVal.trackingNumber ? newVal.trackingNumber : ''

      this.userName = newVal.userName ? newVal.userName : ''
      this.userId = newVal.userId ? newVal.userId : ''
    },
  },

  mounted() {

  },

  methods: {
    closeFilter() {
      this.$emit('close')
    },


    resetFilter() {
      let data = this.resetFilterParams(this, this.filterCounts)

      this.$emit('changeFilter', data)
    },

    changeFilter() {
      let data = this.changeFilterParams(this, this.filterCounts)
      data.date = this.changeDateParams(this, 'date')
      data.ship = this.changeDateParams(this, 'ship')

      this.$emit('changeFilter', data)
    },

  },



}
</script>

<style scoped>

</style>