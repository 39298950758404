<template>
  <div class="fragment">

    <template v-if="$store.getters.getEasyOrders.length > 0 && !$store.getters.getEasyOrdersLoading">
      <div class="site-table-wrap order-fbm-table"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate([
            'fbm_id',
            'fbm_Created',
            'fbm_TrackingNumber',
            'common_manage',
            'common_edit',
            'common_delete',
          ])"></div>
        <table class="site-table" v-if="!isMobileFunc()">
          <thead>
          <tr>
            <th></th>
            <th>{{$t('fbm_id.localization_value.value')}}</th>
            <th width="10%">{{$t('fbm_Created.localization_value.value')}}</th>
<!--            <th v-if="isAdmin">{{$t('common_user.localization_value.value')}}</th>-->
            <th>{{$t('fbm_Recepient.localization_value.value')}}</th>
            <th>{{$t('common_country.localization_value.value')}}</th>
            <th class="white-space-nowrap">{{$t('importO_orderType.localization_value.value')}}</th>
            <!--<th>{{$t('fbm_Lots.localization_value.value')}}</th>-->
            <th>{{$t('fbm_Ship.localization_value.value')}}</th>
            <th>{{$t('fbm_TrackingNumber.localization_value.value')}}</th>
<!--            <th>{{$t('fbm_DHLTracknumber.localization_value.value')}}</th>-->
<!--            <th v-if="!navTabs[FBM_ORDER_TYPES.FBM_ORDER_WAREHOUSE.name].active">{{$t('fbm_ConsoID.localization_value.value')}}</th>-->
            <th>{{$t('fbm_WasSentAt.localization_value.value')}}</th>
<!--            <th v-if="!navTabs[FBM_ORDER_TYPES.FBM_ORDER_LABEL.name].active && (isUserPrepayment || isAdmin)">{{$t('fbm_PrepaidTotal.localization_value.value')}}</th>-->
<!--            <th v-if="!navTabs[FBM_ORDER_TYPES.FBM_ORDER_LABEL.name].active && (isUserPrepayment || isAdmin)">{{$t('fbm_Paid.localization_value.value')}}</th>-->
            <th>{{$t('common_manage.localization_value.value')}}</th>
          </tr>
          </thead>
          <tbody>

          <tr
              v-for="(item, index) in $store.getters.getEasyOrders"
              :key="index"
              v-bind:class="{'site-table__draft-row': item.in_draft === 1}"
          >
            <td>
              <DefaultCheckbox
                  v-if="getEasyOrderType(item.order_type) !== 'fedex'"
                  class="empty-label"
                  :selectedNow="selectedNow"
                  :dataValue="item.order_id"
              />
            </td>
            <td>
              <router-link
                  :to="getShowLink(item)"
                  class="table-link btn-style">
                #{{ item.order_id }}
              </router-link>
            </td>
            <td>{{ item.created_at | moment("DD MMM, YYYY") }}</td>
            <td>
              <ValueHelper
                  :value="item.customer"
              />
            </td>
            <td>
              <ValueHelper
                  :value="item.country"
              />
            </td>
            <td>
              <ValueHelper
                  :value="item.service_type"
              />
            </td>
            <td>
              <ValueHelper
                  :value="item.shipping_cost"
              />
            </td>
            <td>
              <ValueHelper
                  :value="item.tracking_number"
              />
            </td>
<!--            <td>-->
<!--              &lt;!&ndash;              <ValueHelper&ndash;&gt;-->
<!--              &lt;!&ndash;                  v-if="item.system_tracking && item.order_type === 'econom'"&ndash;&gt;-->
<!--              &lt;!&ndash;                  :value="item.system_tracking"&ndash;&gt;-->
<!--              &lt;!&ndash;              />&ndash;&gt;-->
<!--              <template v-if="item.system_tracking && item.order_type === 'econom'">-->
<!--                <a :href="'https://expressposhta.com/search/' + item.system_tracking" target="_blank" class="table-link btn-style">-->
<!--                  {{item.system_tracking}}-->
<!--                </a>-->
<!--              </template>-->
<!--              <template v-else>—</template>-->
<!--            </td>-->
            <td>
              <ValueHelper
                  :value="item.send_at"
                  :date="true"
                  :dateType="'DD MMM, YYYY'"
              />
            </td>
<!--            <td v-if="isAdmin">-->
<!--              <TableUserColumn-->
<!--                  v-if="isAdmin"-->
<!--                  :item="item"-->
<!--              />-->
<!--            </td>-->
            <td>
              <div class="d-flex">
                <ManagerButton @reloadManagerBtn="$store.commit('setGlobalUpdateManagerButton', Math.random()) "
                               :key="$store.getters.getGlobalUpdateManagerButton">

                  <template slot="item"
                            v-if="item.status === 'completed' && item.courier &&
                            item.courier.user_proform_document_package_file && item.courier.user_proform_document_package_file?.uuid  &&
                            item.courier.alarm_status !== 1 && (item.courier.order_sender_type === 'by_himself' || isAdmin)"
                  >
                    <LinkButton
                        class="mr-3 white-space-nowrap"
                        :value="$t('common_DocsFedEx.localization_value.value')"
                        :type="'docs'"
                        :target="'_blank'"
                        :link="$store.getters.GET_PATHS.getPdfFile + '/' + item.courier.user_proform_document_package_file?.uuid + '?print=true' + `&orderFEDEX=${item.order_id}`"
                        v-bind:class="{'disabled-btn' : item.courier && item.courier.enabled_user_proform_document === false}"
                    />
                  </template>

                  <template slot="item"
                            v-if="item.status !== 'completed'"
                  >
                    <LinkButton
                        class="mr-3 white-space-nowrap"
                        :value="item.in_draft ? $t('common_EditDraft.localization_value.value') : $t('common_edit.localization_value.value')"
                        :type="'edit'"
                        :link="getEditLink(item)"
                    />
                  </template>

                  <template slot="item"
                            v-if="item.status !== 'completed'"
                  >
                    <LinkButton
                        class="mr-3 white-space-nowrap"
                        :value="$t('common_delete.localization_value.value')"
                        :type="'delete'"
                        @click.native="$emit('deleteOrder', item)"
                    />
                  </template>

                  <template slot="item"
                            v-if="getEasyOrderType(item.order_type) === 'fedex'"
                  >
                    <LinkButton
                        class="mr-3 white-space-nowrap"
                        :value="$t('common_copy.localization_value.value')"
                        :type="'copy'"
                        @click.native="copyExpress(item.order_id, true)"
                    />
                  </template>

                  <template slot="item">
                    <LinkButton
                        class="mr-3 white-space-nowrap"
                        :value="$t('common_termalPrint.localization_value.value')"
                        :type="'print'"
                        @click.native="$emit('thermalPrint', {id: [item.order_id], type: getEasyOrderType(item.order_type)})"
                    />
                  </template>

                  <template slot="item"
                            v-if="getEasyOrderType(item.order_type) === 'fedex' && item.status === 'completed' && (!_.has(item.warehouse, 'id') || isAdmin)"
                  >
                    <LinkButton
                        class="mr-3 white-space-nowrap"
                        :value="$t('common_Proforma.localization_value.value')"
                        :type="'proforma'"
                        @click.native="exportDocsExpress(item.order_id)"
                    />
                  </template>

                  <template slot="item"
                            v-if="!_.has(item.warehouse, 'id') &&
                        (
                          item.status === 'completed' &&
                          (!availableCourierTime) &&
                          canCallCourier(item)
                        ) && getEasyOrderType(item.order_type) === 'fedex'"
                  >
                    <LinkButton
                        class="mr-3 white-space-nowrap"

                        :value="$t('common_CallPickup.localization_value.value')"
                        :type="'pickup'"
                        @click.native="showCallingCourierPopup(item)"
                    />
                  </template>

                  <template slot="item"
                            v-if="(item.status === 'completed' && canCancelCourier(item) ) || (item.courier && item.courier.pickup_data)"
                  >
                    <LinkButton
                        class="mr-3 white-space-nowrap"
                        :value="$t('common_courierInfo.localization_value.value')"
                        :type="'pickup'"
                        @click.native="showCourierDetailPopup(item)"
                    />
                  </template>

                  <template slot="item"
                            v-if="(item.status === 'completed' && canCancelCourier(item) ) || (isAdmin && item.courier && item.courier.pickup_data)"
                  >
                    <LinkButton
                        class="mr-3 white-space-nowrap"
                        :value="$t('common_CancelCourier.localization_value.value')"
                        :type="'pickup'"
                        @click.native="expressCancelCourierPopup(item)"
                    />
                  </template>
                </ManagerButton>
              </div>
            </td>
          </tr>
          </tbody>
        </table>

        <div class="table-card" v-else>
          <div class="table-card__list">
            <div class="table-card__item"
                 v-for="(item, index) in $store.getters.getEasyOrders"
                 :class="{'draft-row': item.in_draft === 1}"
                 :key="index"
            >
              <EasyOrdersTableSectionTableMobile
                  :item="item"
                  :selectedNow="selectedNow"
                  :navTabs="navTabs"
                  :getEditLink="getEditLink(item)"
                  :getShowLink="getShowLink(item)"
                  :canCallCourier="canCallCourier(item)"
                  :availableCourierTime="availableCourierTime"
                  @deleteOrder="(val) => $emit('deleteOrder', val)"
                  @thermalPrint="$emit('thermalPrint', {id: [item.order_id], type: getEasyOrderType(item.order_type)})"
                  @copyExpress="copyExpress(item.order_id, true)"
                  @exportDocsExpress="exportDocsExpress(item.order_id)"
                  @showCallingCourierPopup="showCallingCourierPopup(item)"
                  @showCourierDetailPopup="showCourierDetailPopup(item)"
                  @expressCancelCourierPopup="expressCancelCourierPopup(item)"
              />
            </div>
          </div>
        </div>
      </div>


      <div class="table-bottom-btn">
        <div class="table-bottom-btn__inner">
          <ShowMore
              v-if="$store.getters.getEasyOrdersCommonList.next_page_url !== null && $store.getters.getEasyOrders.length > 0"
              @click.native="$emit('showMore')"
              v-bind:class="{'disabled-btn' : $store.getters.getNextEasyOrdersPage}"
              :count="$store.getters.getEasyOrdersCommonList.total - $store.getters.getEasyOrdersForPage * $store.getters.getEasyOrdersCommonList.current_page < $store.getters.getEasyOrdersForPage ?
                  $store.getters.getEasyOrdersCommonList.total - $store.getters.getEasyOrdersForPage * $store.getters.getEasyOrdersCommonList.current_page:
                  $store.getters.getEasyOrdersForPage"
          />
<!--          <ExportBtn-->
<!--              class="table-bottom-btn__right"-->
<!--              :customType="_.has($store.getters.getEasyOrdersCommonList, 'addition_export') ? $store.getters.getEasyOrdersCommonList.addition_export : null"-->
<!--              @downloadFiles="type => $emit('downloadFiles', type)"-->
<!--          />-->
        </div>
      </div>
    </template>

    <template v-if="$store.getters.getEasyOrdersLoading === false && $store.getters.getEasyOrders.length === 0">
      <NoResult
          :countFilterParams="countFilterParams"
          :title="$t('common_noDataYet.localization_value.value')"
          :titleWithFilter="$t('common_noResult.localization_value.value')"
          :text="$t('common_noDataYetTxt.localization_value.value')"
          :textWithFilter="$t('common_noResultSeems.localization_value.value')"
          @resetFilter="$emit('resetFilter')"
      />
    </template>

<!--    <StatusOrderDetailsPopup-->
<!--        @close="closeStatusOrderDetailsPopup"-->
<!--        v-if="isModalStatusOrderDetailsPopup"-->
<!--    />-->


    <CallingCourierPopup
        v-if="isModalCallingCourierPopup"
        :orderPopupData="orderPopupData"
        :availableCourierTime="availableCourierTime"
        @close="closeCallingCourierPopup"
        @reload="$emit('reload')"
        @successPickup="confirmCallCourier"
    />

    <CourierDetailPopup
        v-if="isModalCourierDetailPopup"
        :item="courierDetailData"
        @close="closeCourierDetailPopup"
    />

    <CancelCourierPopup
        v-if="isModalCancelCourierPopup"
        :id="orderId"
        :orderPopupData="orderPopupData"
        @close="closeCancelCourierPopup"
        @reload="$emit('reload')"
    />

  </div>
</template>

<script>
import DefaultCheckbox from "@/components/UI/checkboxes/DefaultCheckbox/DefaultCheckbox";
import ShowMore from "@/components/coreComponents/Buttons/ShowMore/ShowMore";
import NoResult from "@/components/coreComponents/NoResult/NoResult";
import {mixinDetictingMobile} from "@/mixins/mobileFunctions";
import LinkButton from "@/components/UI/buttons/LinkButton/LinkButton";
import ValueHelper from "@/components/coreComponents/ValueHelper/ValueHelper";
import {easyOrdersMixin} from "@/mixins/easyOrdersMixins/easyOrdersMixin";
import EasyOrdersTableSectionTableMobile
  from "@/components/modules/EasyOrdersModule/components/EasyOrdersTable/EasyOrdersTableSection/EasyOrdersTableSectionTable/EasyOrdersTableSectionTableMobile/EasyOrdersTableSectionTableMobile";
import CourierDetailPopup from "@/components/modules/ExpressOrdersModule/popups/CourierDetailPopup/CourierDetailPopup";
import CancelCourierPopup from "@/components/modules/ExpressOrdersModule/popups/CancelCourierPopup/CancelCourierPopup";
import CallingCourierPopup
  from "@/components/modules/ExpressOrdersModule/popups/CallingCourierPopup/CallingCourierPopup";
import ManagerButton from "@/components/UI/buttons/ManagerButton/ManagerButton";

export default {
  name: "EasyOrdersTableSectionTable",

  components: {
    ManagerButton,
    CancelCourierPopup,
    CourierDetailPopup,
    CallingCourierPopup,
    DefaultCheckbox,
    EasyOrdersTableSectionTableMobile,
    ValueHelper,
    LinkButton,
    NoResult,
    ShowMore,
  },

  mixins: [
      mixinDetictingMobile,
      easyOrdersMixin,
  ],

  props: {
    selectedNow: Boolean,
    countFilterParams: Number,
    navTabs: Object,
  },

  watch: {
    loadUserAuthorizedData() {
      this.checkUserCourierAvailableTime()
    },
  },

  data() {
    return {
      orderId: '',

      isModalCallingCourierPopup: false,
      isModalCourierDetailPopup: false,
      isModalCancelCourierPopup: false,

      orderPopupData: {},
      courierDetailData: {},

      availableCourierTime: null,
    }
  },

  mounted() {
    if(this.loadUserAuthorizedData){
      this.checkUserCourierAvailableTime()
    }
  },

  methods: {
    copyExpress(id, table = false) {
      this.$store.dispatch('copyExpress', {order_express_id: id}).then((response) => {
        switch (this.getResponseStatus(response)) {
            /**
             * Success
             */
          case this.$store.getters.GET_ERRORS.SUCCESS_CODE: {
            this.openNotify('success', 'common_notificationRecordCreated')
            if(!table){
              this.$router.push(this.$store.getters.GET_PATHS.expressOrders)
            } else {
              this.$emit('reload')
            }
            break
          }
            /**
             * Validation Error
             */
          case this.$store.getters.GET_ERRORS.VALIDATION_ERROR_CODE: {
            let errors = response.response.data.errors;
            this.notifyErrorHelper(errors)
            break
          }
            /**
             * Undefined Error
             */
          default: {
            this.openNotify('error', 'common_notificationUndefinedError')
          }
        }
      })
    },

    getEditLink(item) {
      let type = this.getEasyOrderType(item.order_type)

      return this.$store.getters.GET_PATHS.easyOrderEdit + `/${type}/` + item.order_id
    },

    getShowLink(item) {

      let type = this.getEasyOrderType(item.order_type)

      return this.$store.getters.GET_PATHS.easyOrderShow + `/${type}/` + item.order_id
    },

    exportDocsExpress(id) {
      this.$store.dispatch('getExportCommercialInvoice', {order_express_id: id}).then(response => {
        this.globalDownloadBase64File('application/docx', response.data.data.commercial_invoice_file, `FedEx docs.docx`)
      }).catch(error => this.createErrorLog(error))
    },

    canCallCourier(item) {
      return ((!item?.courier?.pickup_data && !item?.courier?.cancel_pickup_data) || (!item?.courier?.pickup_data))
    },

    canCancelCourier(item) {
      return (!item?.courier?.cancel_pickup_data && item?.courier?.pickup_data)
    },

    showCallingCourierPopup(item) {
      let type = {
        id: 2,
        name: 'fedex',
      }

      let senderData = item.user?.use_alternative_address === 1 ? item.user?.userAlternativeContact : item.user?.userPersonalContact
      let senderWarehouseData = item.warehouse?.id ? item.warehouse : null

      this.orderPopupData = {
        id: item.order_id,
        name: senderWarehouseData ? senderWarehouseData?.sender_name : senderData?.user_full_name,
        phone: senderWarehouseData ? senderWarehouseData?.sender_phone : senderData?.phone,
        deliveryDate: item?.delivery_date,
        deliveryTypeName: type?.name,
        deliveryTypeId: type?.id,

        country: {
          id: senderWarehouseData ? senderWarehouseData?.country.id : senderData?.country?.id,
          label: senderWarehouseData ? senderWarehouseData?.country?.name : senderData?.country?.name,
          code_iso_2: senderWarehouseData ? senderWarehouseData?.country?.code_iso_2 : senderData?.country?.code_iso_2,
        },
        city: senderWarehouseData ? senderWarehouseData?.sender_city : senderData?.city,
        address: senderWarehouseData ? senderWarehouseData?.sender_address : senderData?.address,
        region: senderWarehouseData ? senderWarehouseData?.sender_contact?.region?.name : senderData?.region?.name,
        zip: senderWarehouseData ? senderWarehouseData?.sender_postcode : senderData?.zip,
      }

      this.isModalCallingCourierPopup = true
    },

    closeCallingCourierPopup() {
      this.isModalCallingCourierPopup = false
    },

    closeCourierDetailPopup() {
      this.isModalCourierDetailPopup = false
    },

    expressCancelCourierPopup(item) {
      this.orderId = item.order_id
      let type = {
        id: 2,
        name: 'fedex',
      }
      this.orderPopupData = {
        deliveryTypeName: type.name,
      }
      this.isModalCancelCourierPopup = true
    },

    closeCancelCourierPopup() {
      this.orderId = -1
      this.isModalCancelCourierPopup = false
    },

    confirmCallCourier() {
      this.$emit('reload')
      this.isModalCallingCourierPopup = false
    },

    checkUserCourierAvailableTime(){
      this.$store.dispatch('identifyPickupCity',
          {user_id: this.loadUserAuthorizedData.user.id}
      ).then(response => {
        this.availableCourierTime = this.getRespData(response).courier_city
      }).catch(error => this.createErrorLog(error))

    },

    showCourierDetailPopup(item) {
      this.courierDetailData = {
        confirmationNumber: item?.courier?.pickup_data?.PickupConfirmationNumber,
        courierArrivalDate: item?.courier?.courier_arrival_date,
        contactPersonName: item?.courier?.courier_alternative_address ? item?.courier?.courier_alternative_address?.contact_name : item?.warehouse !== null ? item?.warehouse?.sender_name : item?.courier?.sender_contact_name,
        contactPhone: item?.courier?.courier_alternative_address ? item?.courier?.courier_alternative_address?.phone : item?.warehouse !== null ? item?.warehouse?.sender_phone : item?.courier?.sender_phone_number,
        packageLocation: item?.courier?.courier_alternative_address ? item?.courier?.courier_alternative_address?.address : item?.warehouse !== null ? item?.warehouse?.sender_address : item?.courier?.sender_contact_address,
      }

      if(item?.courier?.courier_from_to_time !== null) {
        let courierTime = item?.courier?.courier_from_to_time.split('|')
        this.courierDetailData["timeFrom"] = this.$moment(courierTime[0], 'HH:mm').format('HH:mm')
        this.courierDetailData["timeTo"] = this.$moment(courierTime[1], 'HH:mm').format('HH:mm')
      }

      this.isModalCourierDetailPopup = true

    },

    expressCancelCourier(orderId){
      let type = {
        id: 2,
        name: 'fedex',
      }
      this.$store.dispatch('cancelCourier', {
        type: type.name,
        data: {
          'order_express_id': orderId,
        }
      }).then((response) => {
        if (this._.has(response, 'data') && response.status === this.$store.getters.GET_ERRORS.SUCCESS_CODE) {
          this.openNotify('success', 'common_notificationStatusChanged')

          this.$emit('reload')

        } else if(response.response.status === this.$store.getters.GET_ERRORS.VALIDATION_ERROR_CODE) {
          let errors = response.response.data.errors;

          // let errTxt = errors
          this.openNotify('error', {txtServer: errors})
        } else {
          this.openNotify('error', 'common_notificationUndefinedError')
        }
      })
    },
  },


}
</script>

<style scoped>

</style>