<template>
  <div class="fragment">
    <div class="order-create__row custom-row">
      <div class="custom-col custom-col--33 custom-col--sm-100"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['common_comment'])"></div>
        <TextareaDefault
            :label="$t('common_comment.localization_value.value')"
            v-model="FBM.data.Embroidery.data.comment"
        />
      </div>
    </div>
    <div class="order-create__row custom-row">
      <div class="custom-col custom-col--16 custom-col--sm-50"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['calculatorEngraving_NumberOfStitches'])"></div>
        <DefaultInput
            :label="$t('calculatorEngraving_NumberOfStitches.localization_value.value')"
            :type="'text'"
            :placeholder="''"
            v-bind:class="{'ui-no-valid': FBM.data.Embroidery.validation.stitches}"
            :errorTxt="$t(`${FBM.data.Embroidery.validationTranslate.stitches}.localization_value.value`)"
            :error="FBM.data.Embroidery.validation.stitches"
            v-model="FBM.data.Embroidery.data.stitches"
            @input.native="changeEngravingPrice($event)"
            @change="calculateEngravingPrice"
        />
      </div>
      <div class="custom-col custom-col--16 custom-col--sm-50"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['calculatorEngraving_quantity'])"></div>
        <DefaultInput
            :label="$t('calculatorEngraving_quantity.localization_value.value')"
            :type="'text'"
            :placeholder="''"
            v-bind:class="{'ui-no-valid': FBM.data.Embroidery.validation.quantity}"
            :errorTxt="$t(`${FBM.data.Embroidery.validationTranslate.quantity}.localization_value.value`)"
            :error="FBM.data.Embroidery.validation.quantity"
            v-model="FBM.data.Embroidery.data.quantity"
            @input.native="changeQuantity($event)"
            @change="calculateEngravingPrice"
        />
      </div>
      <div class="custom-col custom-col--16 custom-col--sm-50"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['calculatorEngraving_product',])"></div>
        <DefaultSelect
            :options="embroideryCustomOptions"
            :label="$t('calculatorEngraving_product.localization_value.value')"
            :otherValue="'calculatorCustomization'"
            :selected="FBM.data.Embroidery.data.customization"
            @change="changeCustomization"
        />
      </div>
      <div class="custom-col custom-col--16 custom-col--sm-50"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['fbm_engravingPrice'])"></div>
        <DefaultInput
            :label="$t('fbm_engravingPrice.localization_value.value')"
            :type="'text'"
            :disabled="true"
            v-model="FBM.data.Embroidery.data.resultCost"
        />
      </div>
    </div>

    <div
        v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
      <div class="admin-edit" @click="editTranslate(['fbm_embroideryAcceptFiles'])"></div>
      {{ $t('fbm_embroideryAcceptFiles.localization_value.value') }}
    </div>
    <div class="order-create__row custom-row">
      <div class="custom-col position-relative"
           v-if="FBM.data.Embroidery.data.File.data.maxCountFiles - Object.keys(FBM.data.Embroidery.data.File.data.downloadFiles).length > 0">
        <DropZone class="drop-zone-bg"
                  :parentFiles="FBM.data.Embroidery.data.File.data.files"
                  :multiple="true"
                  :maxCount="FBM.data.Embroidery.data.File.data.maxCountFiles - FBM.data.Embroidery.data.File.data.downloadFiles.length"
                  :maxSize="FBM.data.Embroidery.data.File.data.maxSizeFiles"
                  @changeImg="(files, maxSize) => {changeFile(files, maxSize)}"
                  :accept="'/*'"
        />
        <div class="error-field ml-3" v-if="FBM.data.Embroidery.data.File.validation.files">
          {{ $t('common_fileIsRequired.localization_value.value') }}
        </div>
      </div>

      <div class="custom-col mt-3"
           v-if="Object.keys(FBM.data.Embroidery.data.File.data.downloadFiles).length > 0"
      >
        <div class="custom-row product-img-row">
          <div class="custom-col custom-col--16 custom-col--md-25 custom-col--sm-100"
               v-for="(item, index) in FBM.data.Embroidery.data.File.data.downloadFiles"
               :key="index">
            <DownloadFileBlock
                :file="item"
                :customTypeFile="'dst'"
                @removeFile="(id) => {removeFile(id, index)}"
            />
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import DefaultInput from "@/components/UI/inputs/DefaultInput/DefaultInput";
import DownloadFileBlock from "@/components/coreComponents/DownloadFileBlock/DownloadFileBlock";
import DropZone from "@/components/coreComponents/DropZone/DropZone";
import DefaultSelect from "@/components/UI/selectiones/DefaultSelect/DefaultSelect";
import TextareaDefault from "@/components/UI/textarea/TextareaDefault/TextareaDefault";
export default {
  name: "Embroidery",
  components: {TextareaDefault, DefaultSelect, DropZone, DownloadFileBlock, DefaultInput},

  props: {
    FBM: Object,
    embroideryCustomOptions: Array,
  },

  mounted() {

  },

  methods: {

    changeCustomization(val) {
      let oldCustomization = this.FBM.data.Embroidery.data.customization
      this.FBM.data.Embroidery.data.customization = val;
      this.calculateEngravingPrice();
      this.$emit('changeCustomization', oldCustomization, val)
    },

    changeQuantity(event) {
      this.FBM.data.Embroidery.data.quantity = event.target.value.replace(',', '.').replace(/[^0-9.]/g, '')
    },

    changeEngravingPrice(event) {
      this.FBM.data.Embroidery.data.stitches = event.target.value.replace(',', '.').replace(/[^0-9.]/g, '')
    },

    calculateEngravingPrice() {
      if(
          (!this.FBM.data.Embroidery.data.stitches || this.FBM.data.Embroidery.data.stitches.length === 0) ||
          (!this.FBM.data.Embroidery.data.quantity || this.FBM.data.Embroidery.data.quantity.length === 0)
      ) {
        return
      }

      let data = this.FBM.data.Embroidery.prepareData()

      this.$store.dispatch('getEmbroideryCalcResult', data).then(response => {
        if(this.getRespData(response)?.rate) {
          this.FBM.data.Embroidery.data.resultCost = parseFloat(this.getRespData(response)?.rate).toFixed(2)
        }
      })
    },

    changeFile(files, maxSizeError) {
      this.FBM.data.Embroidery.data.File.setFiles(files)

      this.FBM.data.Embroidery.data.File.setFilesMaxSizeError(maxSizeError)

      this.calculateEngravingPrice()
    },

    removeFile(id, index){

      let text = {
        title: 'common_AreYouSure',
        txt: '',
        yes: 'common_confirmDelete',
        no: 'common_confirmNo'
      }

      let confirm = () => {

        this.$store.dispatch('removeUserDocument', id).then(() => {

          this.FBM.data.Embroidery.data.File.removeDownloadFile(index)
          // this.FBM.data.Embroidery.data.File.setDownloadFiles([])

          this.calculateEngravingPrice()
        })

        this.$store.commit('setConfirmDeletePopup', false)
        return true
      }

      this.deleteFunc(text, confirm)


    },
  },

}
</script>

<style scoped>

</style>