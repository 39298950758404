<template>
  <div class="fragment">
    <div class="order-create__row custom-row">
      <div class="custom-col custom-col--33 custom-col--sm-100"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['common_comment'])"></div>
        <TextareaDefault
            :label="$t('common_comment.localization_value.value')"
            v-model="FBM.data.TShirtPrinting.data.comment"
        />
      </div>
    </div>
    <div class="order-create__row custom-row">
      <div class="custom-col custom-col--16 custom-col--sm-50"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['calculatorEngraving_quantity'])"></div>
        <DefaultInput
            :label="$t('calculatorEngraving_quantity.localization_value.value')"
            :type="'text'"
            :placeholder="''"
            v-bind:class="{'ui-no-valid': FBM.data.TShirtPrinting.validation.quantity}"
            :errorTxt="$t(`${FBM.data.TShirtPrinting.validationTranslate.quantity}.localization_value.value`)"
            :error="FBM.data.TShirtPrinting.validation.quantity"
            v-model="FBM.data.TShirtPrinting.data.quantity"
            @input.native="changeEngravingPrice($event)"
            @change="(val) => {calculateEngravingPrice(); $emit('changeQuantity', FBM.data.TShirtPrinting.data.customization, val)}"
        />
      </div>

      <div class="custom-col custom-col--16 custom-col--sm-50"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['calculatorEngraving_product',])"></div>
        <DefaultSelect
            :options="dtgPrintingCustomOptions"
            :label="$t('calculatorEngraving_product.localization_value.value')"
            :otherValue="'calculatorCustomization'"
            :selected="FBM.data.TShirtPrinting.data.customization"
            @change="changeCustomization"
        />
      </div>

      <div class="custom-col custom-col--16 custom-col--sm-50"
           v-if="_.has(FBM.data.TShirtPrinting.data.customization, 'd_m_customizing_variants')"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['calculatorEngraving_productSize',])"></div>
        <DefaultSelect
            :options="FBM.data.TShirtPrinting.data.customization.d_m_customizing_variants"
            :label="$t('calculatorEngraving_productSize.localization_value.value')"
            :optionsLabel="'name'"
            :selected="FBM.data.TShirtPrinting.data.size"
            @change="(val) => {FBM.data.TShirtPrinting.data.size = val}"
        />
      </div>

<!--      <div class="custom-col custom-col&#45;&#45;16 custom-col&#45;&#45;sm-50"-->
<!--           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">-->
<!--        <div class="admin-edit" @click="editTranslate(['calculatorEngraving_color'])"></div>-->
<!--        <DefaultSelect-->
<!--            :options="colorOptions"-->
<!--            :optionsLabel="'translation'"-->
<!--            :label="$t('calculatorEngraving_color.localization_value.value')"-->
<!--            v-bind:class="{'ui-no-valid': FBM.data.TShirtPrinting.validation.color}"-->
<!--            :errorTxt="$t(`${FBM.data.TShirtPrinting.validationTranslate.color}.localization_value.value`)"-->
<!--            :error="FBM.data.TShirtPrinting.validation.color"-->
<!--            :selected="getColor"-->
<!--            @change="changeColor"-->
<!--        />-->
<!--      </div>-->
<!--      <div class="custom-col custom-col&#45;&#45;16 custom-col&#45;&#45;sm-50"-->
<!--           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">-->
<!--        <div class="admin-edit" @click="editTranslate(['calculatorEngraving_quality'])"></div>-->
<!--        <DefaultSelect-->
<!--            :options="qualityOptions"-->
<!--            :optionsLabel="'translation'"-->
<!--            :label="$t('calculatorEngraving_quality.localization_value.value')"-->
<!--            v-bind:class="{'ui-no-valid': FBM.data.TShirtPrinting.validation.quality}"-->
<!--            :errorTxt="$t(`${FBM.data.TShirtPrinting.validationTranslate.quality}.localization_value.value`)"-->
<!--            :error="FBM.data.TShirtPrinting.validation.quality"-->
<!--            :selected="getQuality"-->
<!--            @change="changeQuality"-->
<!--        />-->
<!--      </div>-->
      <div class="custom-col custom-col--16 custom-col--sm-50"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['fbm_engravingPrice'])"></div>
        <DefaultInput
            :label="$t('fbm_engravingPrice.localization_value.value')"
            :type="'text'"
            :disabled="true"
            v-model="FBM.data.TShirtPrinting.data.resultCost"
        />
      </div>
    </div>

    <div
        v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
      <div class="admin-edit" @click="editTranslate(['fbm_engravingAcceptFiles'])"></div>
      {{ $t('fbm_engravingAcceptFiles3.localization_value.value') }}
    </div>
    <div class="order-create__row custom-row">
      <div class="custom-col position-relative"
           v-if="FBM.data.TShirtPrinting.data.File.data.maxCountFiles - Object.keys(FBM.data.TShirtPrinting.data.File.data.downloadFiles).length > 0">
        <DropZone class="drop-zone-bg"
                  :parentFiles="FBM.data.TShirtPrinting.data.File.data.files"
                  :multiple="true"
                  :maxCount="FBM.data.TShirtPrinting.data.File.data.maxCountFiles - FBM.data.TShirtPrinting.data.File.data.downloadFiles.length"
                  :maxSize="FBM.data.TShirtPrinting.data.File.data.maxSizeFiles"
                  @changeImg="(files, maxSize) => {changeFile(files, maxSize)}"
                  :accept="'/*'"
        />
        <div class="error-field ml-3" v-if="FBM.data.TShirtPrinting.data.File.validation.files">
          {{ $t('common_fileIsRequired.localization_value.value') }}
        </div>
      </div>

      <div class="custom-col mt-3"
           v-if="Object.keys(FBM.data.TShirtPrinting.data.File.data.downloadFiles).length > 0"
      >
        <div class="custom-row product-img-row">
          <div class="custom-col custom-col--16 custom-col--md-25 custom-col--sm-100"
               v-for="(item, index) in FBM.data.TShirtPrinting.data.File.data.downloadFiles"
               :key="index">
            <DownloadFileBlock
                :file="item"
                @removeFile="(id) => {removeFile(id, index)}"
            />
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import DefaultInput from "@/components/UI/inputs/DefaultInput/DefaultInput";
import DropZone from "@/components/coreComponents/DropZone/DropZone";
import DownloadFileBlock from "@/components/coreComponents/DownloadFileBlock/DownloadFileBlock";
import DefaultSelect from "@/components/UI/selectiones/DefaultSelect/DefaultSelect";
import TextareaDefault from "@/components/UI/textarea/TextareaDefault/TextareaDefault";
export default {
  name: "TShirtPrinting",
  components: {TextareaDefault, DefaultSelect, DownloadFileBlock, DropZone, DefaultInput},

  props: {
    FBM: Object,
    // colorOptions: Array,
    // qualityOptions: Array,
    dtgPrintingCustomOptions: Array,
  },

  data() {
    return {}
  },

  computed: {

    // getColor() {
    //   if(!this.FBM.data.TShirtPrinting.data.color) return null
    //   return this._.find(this.colorOptions, {value: this.FBM.data.TShirtPrinting.data.color})
    // },
    // getQuality() {
    //   if(!this.FBM.data.TShirtPrinting.data.quality) return null
    //   return  this._.find(this.qualityOptions, {value: this.FBM.data.TShirtPrinting.data.quality})
    // },
  },

  mounted() {



  },

  methods: {
    changeColor(val) {
      this.FBM.data.TShirtPrinting.data.color = val.value
      this.calculateEngravingPrice()
    },

    changeCustomization(val) {
      let oldCustomization = this.FBM.data.TShirtPrinting.data.customization
      this.FBM.data.TShirtPrinting.data.customization = val
      this.calculateEngravingPrice()
      this.FBM.data.TShirtPrinting.data.size = null
      this.$emit('changeCustomization', oldCustomization, val, this.FBM.data.TShirtPrinting.data.quantity)
    },

    changeQuality(val) {
      this.FBM.data.TShirtPrinting.data.quality = val.value
      this.calculateEngravingPrice()
    },

    changeEngravingPrice(event) {
      this.FBM.data.TShirtPrinting.data.quantity = event.target.value.replace(',', '.').replace(/[^0-9.]/g, '')
    },

    calculateEngravingPrice() {
      if (
          !this.FBM.data.TShirtPrinting.data.quantity ||
          this.FBM.data.TShirtPrinting.data.quantity.length === 0
          // this.FBM.data.TShirtPrinting.data.color === null ||
          // this.FBM.data.TShirtPrinting.data.quality === null
      ) {
        return
      }

      let data = this.FBM.data.TShirtPrinting.prepareData()
          // {
        // numberOfStitches: this.FBM.data.TShirtPrinting.data.stitches,
      // }

      this.$store.dispatch('getTShirtCalcResult', data).then(response => {
        if (this.getRespData(response)?.rate) {
          this.FBM.data.TShirtPrinting.data.resultCost = parseFloat(this.getRespData(response)?.rate).toFixed(2)
        }
      })
    },

    changeFile(files, maxSizeError) {
      this.FBM.data.TShirtPrinting.data.File.setFiles(files)

      this.FBM.data.TShirtPrinting.data.File.setFilesMaxSizeError(maxSizeError)

      this.calculateEngravingPrice()
    },

    removeFile(id, index) {

      let text = {
        title: 'common_AreYouSure',
        txt: '',
        yes: 'common_confirmDelete',
        no: 'common_confirmNo'
      }

      let confirm = () => {

        this.$store.dispatch('removeUserDocument', id).then(() => {

          this.FBM.data.TShirtPrinting.data.File.removeDownloadFile(index)
          // this.FBM.data.TShirtPrinting.data.File.setDownloadFiles([])

          this.calculateEngravingPrice()
        })

        this.$store.commit('setConfirmDeletePopup', false)
        return true
      }

      this.deleteFunc(text, confirm)


    },
  },

}
</script>

<style scoped>

</style>