<template>
  <div class="fragment">
    <OrdersFBMShowSection
            :FBM="FBM"
            v-if="loadedProformOptions > -1 && orderLoading"
            @addProformProduct="addProformProduct"
            @removeProformProduct="removeProformProduct"
            @prepareSaveFBM="prepareSaveFBM"
            @changeUser="changeUser"
    />
  </div>
</template>

<script>
  import {OrderFBM} from "../../models/OrderFBM";
  import {proformMixins} from "../../../../../mixins/proformMixins/proformMixins";
  // import {FBM_FROM_TRANSACTIONS} from "../../../../../staticData/staticVariables";
  import {FBMMixins} from "../../../../../mixins/FBMMixins/FBMMixins";
  import OrdersFBMShowSection from "./OrdersFBMShowSection/OrdersFBMShowSection";
  import {userAvatarMixin} from "../../../../../mixins/usersMixins/userAvatarMixin";
  import {vatNumberMixin} from "../../../../../mixins/commonMixins/vatNumberMixin";
  import {hsCodeMixin} from "../../../../../mixins/commonMixins/hsCodeMixin";

  export default {
    name: "OrdersFBMShow",

    components: {
      OrdersFBMShowSection,
    },

    mixins: [proformMixins, FBMMixins, userAvatarMixin, vatNumberMixin, hsCodeMixin],

    props: {
      easyOrder: {
        type: Boolean,
        default: false,
      }
    },

    watch: {
      loadUserAuthorizedData: function () {
        this.getFBMUserAuthorizedData()
        this.initFbmOrder()
      },

      selectedCountry: function(country){
        this.$store.dispatch('getCountryDataById', country.id).then(() => {
          let countryWithCountryGroups = this.$store.getters.getCountryGroup

          this.FBM.setVisibleHSCode(this.getHSCodePermissionsByCountry(countryWithCountryGroups, true))
          this.FBM.setVisibleVatNumber(this.getVatNumberPermissionsByCountry(countryWithCountryGroups), this.FBM.data.visibleVatNumber)
        }).catch(error => this.createErrorLog(error))
      },

      downloadPermissions() {
        this.checkPermission()
      },
    },

    computed: {
      selectedCountry(){
        return this.FBM.data.DeliveryPayPal.delivery.country
      }
    },

    data() {
      return {
        FBM: new OrderFBM(),
        loadedProformOptions: -1,
        orderLoading: false,
      }
    },

    mounted() {

      if(this.easyOrder) {
        this.FBM.data.easyOrderType = true
      }

      let orderId = this.$route.params.id
      let orderType = this.$route.params.type


      if(this.downloadPermissions){
        if(!this.checkPermission()) return
      }

      /**
       * Set OrderId & TypeFBM from route params
       */
      this.FBM.setOrderId(orderId)
      this.FBM.setTypeFBM(orderType)

      if(this.loadUserAuthorizedData){
        this.initFbmOrder()
      }

    },

    methods: {

      initFbmOrder(){
        this.initializeProductProformArray(this.FBM.data.ProformProducts).then(() => {
          let requestType = 'getOrdersFBM'

          if(this.isAdmin){
            requestType = 'getAdminOrdersFBM'
          }

          if(!this.isAdmin && this.isEasyType) {
            requestType = this.isConsolidation ? 'getEasyOrdersFBMConsolidation' : 'getEasyOrdersFBM'
          }

          this.$store.dispatch(requestType, this.FBM.data.Order.getId()).then(response => {
            let order = this.getRespData(response)

            this.orderLoading = true

            /**
             * Set FBM
             */
            this.FBM.setItem(order, this.isAdmin, true)

            /**
             * Set FBM Vat files
             */
            let vatNumber = order.orderFbmInstance?.vat_tax
            if(vatNumber?.file_entity && vatNumber?.file_entity.length > 0){
              this.setVatFiles(vatNumber.file_entity)
            }

            /**
             * Set Engraving Files
             */
            if(order?.orderFbmInstance?.engravings.length > 0 && order?.orderFbmInstance?.engravings[0].width) {
              order?.orderFbmInstance?.engravings.forEach((engravingItem, engravingIndex) => {
                if(engravingItem.files.length === 0) {
                  let engravingFiles = order?.orderFbmInstance?.engraving_files
                  if(engravingFiles){
                    this.setEngravingFile(engravingFiles, engravingIndex)
                  }
                } else {
                  this.setEngravingFile(engravingItem.files, engravingIndex)
                }
              })
            }

            /**
             * Set Embroidery Files
             */
            let embroideryFiles = order?.orderFbmInstance?.embroidery_files
            if(embroideryFiles){
              this.setEmbroideryFile(embroideryFiles)
            }

            /**
             * Set T-Shirt Painting Files
             */
            let tshirtPaintingFiles = order?.orderFbmInstance?.tshirt_painting_files
            if(tshirtPaintingFiles){
              this.setTShirtPaintingFile(tshirtPaintingFiles)
            }

            /**
             * Set Photo Print Files
             */
            let photoPrintFiles = order?.orderFbmInstance?.photo_print_files
            if(photoPrintFiles){
              this.setPhotoPrintFilesFile(photoPrintFiles)
            }

            /**
             * Set Leather Stamping Files
             */
            let leatherStampingFiles = order?.orderFbmInstance?.leather_stamping_files
            if(leatherStampingFiles){
              this.setLeatherStampingFile(leatherStampingFiles)
            }

            /**
             * Set Leather Stamping Files
             */
            let UVPrintingFiles = order?.orderFbmInstance?.customization_print_u_v_files
            if(UVPrintingFiles){
              this.setUVPrintingFile(UVPrintingFiles)
            }

            /**
             * Set permanent vinyl files
             */
            if(order?.orderFbmInstance?.permanent_vinyls[0]?.need_dm_pv) {
              order?.orderFbmInstance?.permanent_vinyls.forEach((customItem, customIndex) => {
                this.setPermanentVinylFile(customItem.files, customIndex)
              })
            }

            /**
             * Set sticker files
             */
            if(order?.orderFbmInstance?.stickers[0]?.need_sticker) {
              order?.orderFbmInstance?.stickers.forEach((customItem, customIndex) => {
                this.setStickerFile(customItem.files, customIndex)
              })
            }

            /**
             * Set heat transfer vinyl files
             */
            if(order?.orderFbmInstance?.heat_transfer_vinyls[0]?.need_htv) {
              order?.orderFbmInstance?.heat_transfer_vinyls.forEach((customItem, customIndex) => {
                this.setHeatTransferVinylFile(customItem.files, customIndex)
              })
            }

            // this.getShippingData().then(() => {
            //   /**
            //    * initialize Shipping Companies
            //    */
            //   this.initShippingCompanies(false, true)
            // })

            if(this.isAdmin){
              this.setUserAvatar(this.FBM.data.User)
            }

            this.loadedProformOptions = true

            /**
             * Set FBM Proform Products
             */
            // this.FBM.data.ProformProducts.map((itemProduct, index) => {
            //   this.initialProformSelectOptions({
            //     val: itemProduct.product.proformData.proformCategory,
            //     item: itemProduct
            //   }).then(() => {
            //     this.loadedProformOptions = index
            //   })
            // })
          }).catch(error => this.createErrorLog(error))
        })
      },

      getFBMUserAuthorizedData() {

      },

      addProformProduct(){
        let newIndex = this.FBM.addProformProduct()
        this.initializeProductProformArray(this.FBM.data.ProformProducts, newIndex)
      },

      removeProformProduct(index) {
        this.FBM.removeProformProduct(index)
      },

      saveFBM({draft = false, edit = false, createLabel = false}) {
        console.log(createLabel);
        if(this.isAdmin) {
          if(!this.FBM.FBMAdminValidation()) return
        }

        let data = this.FBM.prepareFBM(
          draft,
          this.FBM.data.User.getUserId()
        )

        console.log(data);

        if(draft){
          let confirm = () => {
            this.saveOrderFBM(draft, edit, data, 'consolidation').then(status => {
              this.successSave(status)
            })

            this.$store.commit('setSaveInDraftsPopup', false)
            return true
          }

          this.saveInDraftsFunc(confirm)
        } else {
          console.log(3423);
          // this.saveOrderInternal(draft, edit, data, 'ukrposhta').then(response => {
          //   this.createLabel(response.id)
          //   return true
          // })
        }



      },

      saveOrderFBM(draft = false, edit, data, orderType) {
        console.log(orderType);

        let saveType = 'createOrdersFBM'
        if(edit){
          data = {
            id: this.FBM.data.Order.getId(),
            data: data
          }
          saveType = 'updateOrdersFBM'
        }

        return this.$store.dispatch(saveType, data).then(response => {
          if (this._.has(response, 'data') &&
            response.data.status === this.$store.getters.GET_ERRORS.SUCCESS_CODE) {

            if(draft) {
              this.openNotify('success', 'common_notificationRecordChanged')
              return true
            }

            this.openNotify('success', 'common_notificationRecordCreated')
            return true

            // let expressId = response.data.data.id
            //
            // if (createLabel && this.FBM.express.files.length > 0) {
            //   this.createFiles(expressId).then(() => {
            //     this.makeLabelHandler(expressId).then((status) => {
            //       if(!this._.has(status, 'statusPaid')){
            //         this.$router.push(this.$store.getters.GET_PATHS.expressOrders)
            //       }
            //     })
            //   })
            //   return
            // }
            //
            // if (createLabel) {
            //   this.makeLabelHandler(expressId).then((status) => {
            //     if(!this._.has(status, 'statusPaid')){
            //       this.$router.push(this.$store.getters.GET_PATHS.expressOrders)
            //     }
            //   })
            //   return
            // }
            //
            // if(this.FBM.express.files.length > 0) {
            //   this.createFiles(expressId).then(() => {
            //     this.$router.push(this.$store.getters.GET_PATHS.expressOrders)
            //   })
            //   return
            // }

            // this.$router.push(this.$store.getters.GET_PATHS.expressOrders)

          } else if (response.response.data.status === this.$store.getters.GET_ERRORS.VALIDATION_ERROR_CODE) {
            alert('novalid')
          } else {
            this.openNotify('error', 'common_notificationUndefinedError')
          }
        })
      },


      successSave(status) {
        if(!status) return

        this.$router.push(this.$store.getters.GET_PATHS.ordersFBM)
      }

    },

  }
</script>

<style scoped>

</style>
