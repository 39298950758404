<template>

  <div class="fragment">

    <div
        v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
      <div class="admin-edit" @click="editTranslate(['fbm_addWeightScale'])"></div>
      <MainButton
          v-if="(_.has(currentPermissions, PERMISSIONS.USER_CAN_USE_SCALES) || isAdminManage) && !foundDevice && !disabledFields"
          class="mb-3 wfc"
          :value="$t('fbm_addWeightScale.localization_value.value')"
          @click.native="addWeightScaleDevice"
      />
    </div>
    <div class="order-create__product custom-row">

      <div class="order-create__col custom-col"
           v-if="!isAdmin"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['fbm_DimentionsWeightLB'])"></div>
        <SearchSelect
                :options="$store.getters.getProfilesPackaging"
                :label="$t('packaging_NamePackage.localization_value.value')"
                :selected="FBM.data.selectedDimensions"
                :optionsLabel="'package_name'"
                :typeSelect="'packaging'"
                :functionSearch="functionSearchPackaging"
                @change="changeSelectDimensions"
        />
      </div>

      <div class="order-create__col custom-col custom-col--16 custom-col--md-25 custom-col--sm-50"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate([type === 'metric' && !adminWeight ? 'fbm_DimentionsWeightKG' : 'fbm_DimentionsWeightLB'])"></div>
        <DefaultInput
                v-bind:class="{'ui-no-valid': dimensions.validation.weightLB}"
                :error="dimensions.validation.weightLB"
                :errorTxt="$t(`${dimensions.validationTranslate.weightLB}.localization_value.value`)"
                :label="type === 'metric' && !adminWeight ? $t('fbm_DimentionsWeightKG.localization_value.value') : $t('fbm_DimentionsWeightLB.localization_value.value')"
                :type="'text'"
                :placeholder="'e.g. 10'"
                v-model="dimensions.data.weightLB"
                :disabled="disabledFields"
                :autocompleteOff="true"
                @input.native="acceptNumber($event, 'weightLB')"
                @blur="$emit('dimensionsChanged')"
        />
      </div>
      <div class="order-create__col custom-col custom-col--16 custom-col--md-25 custom-col--sm-50"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate([type === 'metric' && !adminWeight ? 'fbm_DimentionsWeightGR' : 'fbm_DimentionsWeightOZ'])"></div>
        <DefaultInput
                v-bind:class="{'ui-no-valid': dimensions.validation.weightOZ}"
                :error="dimensions.validation.weightOZ"
                :errorTxt="$t(`${dimensions.validationTranslate.weightOZ}.localization_value.value`)"
                :label="type === 'metric' && !adminWeight ? $t('fbm_DimentionsWeightGR.localization_value.value') : $t('fbm_DimentionsWeightOZ.localization_value.value')"
                :type="'text'"
                :placeholder="'e.g. 10'"
                v-model="dimensions.data.weightOZ"
                :disabled="disabledFields"
                :autocompleteOff="true"
                @input.native="acceptNumber($event, 'weightOZ')"
                @blur="$emit('dimensionsChanged')"
        />
      </div>
      <div class="order-create__col custom-col custom-col--16 custom-col--md-25 custom-col--sm-50"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['fbm_DimentionsHeight'])"></div>
        <DefaultInput
                v-bind:class="{'ui-no-valid': dimensions.validation.height}"
                :error="dimensions.validation.height"
                :errorTxt="$t(`${dimensions.validationTranslate.height}.localization_value.value`)"
                :label="type === 'metric' ? $t('fbm_DimentionsHeight.localization_value.value') : $t('fbm_DimentionsHeightInch.localization_value.value')"
                :type="'text'"
                :placeholder="'e.g. 10'"
                v-model="dimensions.data.height"
                :disabled="disabledFields"
                :autocompleteOff="true"
                @blur="$emit('dimensionsChanged')"
        />
      </div>
      <div class="order-create__col custom-col custom-col--16 custom-col--md-25 custom-col--sm-50"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['fbm_DimentionsWidth'])"></div>
        <DefaultInput
                v-bind:class="{'ui-no-valid': dimensions.validation.width}"
                :error="dimensions.validation.width"
                :errorTxt="$t(`${dimensions.validationTranslate.width}.localization_value.value`)"
                :label="type === 'metric' ? $t('fbm_DimentionsWidth.localization_value.value') : $t('fbm_DimentionsWidthInch.localization_value.value')"
                :type="'text'"
                :placeholder="'e.g. 10'"
                v-model="dimensions.data.width"
                :disabled="disabledFields"
                :autocompleteOff="true"
                @blur="$emit('dimensionsChanged')"
        />
      </div>
      <div class="order-create__col custom-col custom-col--16 custom-col--md-25 custom-col--sm-50"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['fbm_DimentionsLength'])"></div>
        <DefaultInput
                v-bind:class="{'ui-no-valid': dimensions.validation.dimensionLength}"
                :error="dimensions.validation.dimensionLength"
                :errorTxt="$t(`${dimensions.validationTranslate.dimensionLength}.localization_value.value`)"
                :label="type === 'metric' ? $t('fbm_DimentionsLength.localization_value.value') : $t('fbm_DimentionsLengthInch.localization_value.value')"
                :type="'text'"
                :placeholder="'e.g. 10'"
                v-model="dimensions.data.dimensionLength"
                :disabled="disabledFields"
                :autocompleteOff="true"
                @blur="$emit('dimensionsChanged')"
        />

        <div class="order-create__product-add-to-profile"
             v-if="$store.getters.getIsAdminRights === 'user' &&
               _.has(FBM.data.selectedDimensions, 'package_name')"
             @click="addToProfile">

          <template>
            <v-popover
                    class="site-tooltip"
                    offset="5"
                    placement="top"
                    trigger="hover"
            >
                    <span>
                      <AddToProfileIco/>
                    </span>
              <template slot="popover">
                {{$t('common_AddProfile.localization_value.value')}}
              </template>
            </v-popover>
          </template>
        </div>
      </div>
    </div>
  </div>


</template>

<script>
  import DefaultInput from "../../../../../../UI/inputs/DefaultInput/DefaultInput";
  import {queryFilterMixin} from "../../../../../../../mixins/routeFilterMixins/queryFilterMixin";
  import SearchSelect from "../../../../../../UI/selectiones/SearchSelect/SearchSelect";
  import AddToProfileIco from '../../../../../../../../public/img/UI-group/add-to-profile-ico.svg?inline'
  import {PackingLabel} from "../../../../../ProfilesModule/popups/AddingLabelProfilePopup/model/PackingLabel";
  import MainButton from "@/components/UI/buttons/MainButton/MainButton";
  import {scaleMixins} from "@/mixins/scaleMixins/scaleMixin";

  export default {
    name: "Dimensions",
    components: {
      MainButton,
      SearchSelect,
      DefaultInput,
      AddToProfileIco
    },

    mixins: [queryFilterMixin, scaleMixins],

    props: {
      FBM: Object,
      dimensions: Object,
      type: {
        type: String,
        default: 'metric'
      },
      disabledFields: {
        type: Boolean,
        default: false
      },
      adminWeight: {
        type: Boolean,
        default: false
      },
      isAdminManage: {
        type: Boolean,
        default: false
      },
    },

    data() {
      return {
        options: [],
        PackingLabel: new PackingLabel(),
        weightScaleLB: 0,
        weightScaleOZ: 0,
        foundDevice: false,
        productId: '',
        deviceDataArr: [],
      }
    },

    destroyed() {
      if(navigator.hid) {
        navigator.hid.getDevices().then(devices => {
          let device = this._.find(devices, {vendorId: 2338})
          if(device) {
            device.close();
          }
        })
      }
    },

    async mounted() {
      if(!this.isAdmin) {
        let myQuery = this.createQueryBuilderObject({withUserNewMark: true})
        myQuery.where('order_label_profile', 'fbm')
        let url = this.generateGetParamsFromQuery(myQuery, 10000, 0)

        this.$store.dispatch('fetchProfilesPackaging', url).then((response) => {
          this.options = this.getRespPaginateData(response)
        })
      }



      if((this._.has(this.currentPermissions, this.PERMISSIONS.USER_CAN_USE_SCALES) || this.isAdminManage) && navigator.hid) {
        await navigator.hid.getDevices().then(async devices => {
          let device = this._.find(devices, {vendorId: 2338})
          if(!device) return
          this.foundDevice = true
          await device.open().then(() => {
            this.changeWeightScale(device)
          })
        })
      }
    },

    methods: {

      addWeightScaleDevice() {
        const vendorId = 0x0922;
        navigator.hid.requestDevice({ filters: [{ vendorId }] })
            .then(devices => {
              if(devices.length > 0) {
                this.foundDevice = true
                devices.map(device => {
                  return device.open()
                      .then(() => {
                        this.changeWeightScale(device)
                      })
                })
              }
            })
            .catch(error => {
              console.log(error);
              // this.openNotify('error', {txtServer: errors['tracking_number'][0]})
              // this.openNotify('error', 'common_notificationUndefinedError')
            });
      },

      changeSelectDimensions(val) {
        this.FBM.data.selectedDimensions = val

        this.dimensions.setDimensionsHeight(val?.height ? val.height : '')
        this.dimensions.setDimensionsWidth(val?.width ? val.width : '')
        this.dimensions.setDimensionsLength(val['length'] ? val['length'] : '')
        this.dimensions.setDimensionsWeightLB(val?.weightKg ? parseInt(val.weightKg) : '')
        this.dimensions.setDimensionsWeightOZ(val?.weightGr ? parseInt(val.weightGr) : '')

        this.$emit('dimensionsChanged')
      },

      changeWeightScale(device) {
        device.addEventListener("inputreport", (event) => {
          const { data, device } = event;
          let buffArray = new Uint8Array(data.buffer);
          // console.log(buffArray);

          this.productId = device?.productId
          this.deviceDataArr = buffArray

          let result = this.getScaleDecodeData(buffArray)

          this.weightScaleLB = result.weightScaleFirst
          this.weightScaleOZ = result.weightScaleSecond
        })
      },


      addToProfile() {
        let valid = this.FBM.data.Dimensions.dimensionsPartialValidate({
          height: true,
          width: true,
          dimensionLength: true,
          weightLB: true,
          weightOZ: true,
        })
        if(!valid) return

        let dimensionData = this.FBM.data.Dimensions.dimensionsPrepareData()

        this.PackingLabel.setItem(
          {
            package_name: this.FBM.data.selectedDimensions.package_name,
            height: dimensionData.height,
            width: dimensionData.width,
            length: dimensionData.dimensionLength,
            weightKg: dimensionData.weightLB,
            weightGr: dimensionData.weightOZ,
          },
          this.FBM.data.User.getUser()
        )

        let data = this.PackingLabel.prepareSaveMetric()

        this.$store.dispatch('createProfilesPackaging', data).then((response) => {
          switch (this.getResponseStatus(response)) {
            /**
             * Success
             */
            case this.$store.getters.GET_ERRORS.SUCCESS_CODE: {
              this.openNotify('success', 'common_notificationRecordCreated')
              break
            }
            /**
             * Validation Error
             */
            case this.$store.getters.GET_ERRORS.VALIDATION_ERROR_CODE: {
              let errors = response.response.data.errors;
              this.notifyErrorHelper(errors)
              break
            }
            /**
             * Undefined Error
             */
            default: {
              this.openNotify('error', 'common_notificationUndefinedError')
            }
          }
        })
      },


      functionSearchPackaging(val, loading) {
        loading(true)

        if (this.proformSearchTimeoutTime !== undefined) {
          let dateNow = new Date()
          if (dateNow - this.proformSearchTimeoutTime < 500) {
            clearTimeout(this.proformSearchTimeout)
          }
        }

        this.createTimeOut(val, loading)
      },

      createTimeOut(val, loading) {
        this.proformSearchTimeoutTime = new Date()
        this.proformSearchTimeout = setTimeout(() => {
          this.getProformPackaging(val, loading)
        }, 500)
      },

      getProformPackaging(val = false, loading){
        let userId
        if (this.userId)
          userId = this.userId
        else
          userId = this.$store.getters.getUserProfile.id

        if(!userId) return

        const query = new this.Query();
        let url = '?';
        let myQuery = query
          .for('posts')

        myQuery.where('ProformPackagingUserId', userId)

        // myQuery.where('ProformPackagingIsTemplate', '1')
        myQuery.where('order_label_profile', 'fbm')

        if(val) myQuery.where('ProformPackagingPackageName', val)

        url = url + myQuery.limit(100000).page(0).url().split('?')[1]

        this.$store.dispatch('fetchProfilesPackaging', url).then(() => {
          if(this.$store.getters.getProfilesPackaging.length === 0 && val){
            this.$store.commit('setProfilesPackaging', [{package_name: val}])
            this.FBM.data.selectedDimensions = {package_name: val}
          }
          if(loading)
            loading(false)
        })

      },

      acceptNumber(event, type) {
        if((this._.has(this.currentPermissions, this.PERMISSIONS.USER_CAN_USE_SCALES) || this.isAdminManage) &&
            this.foundDevice && event.target.value === ' ') {
          this.dimensions.data.weightLB = this.weightScaleLB
          this.dimensions.data.weightOZ = this.weightScaleOZ

          // let scale = {
          //   first: this.type === 'metric' ? 'kg' : 'lb',
          //   second: this.type === 'metric' ? 'gr' : 'oz',
          // }
          //
          // let error = {
          //   stack: `weightScale = ${this.weightScaleLB}${scale.first} ${this.weightScaleOZ}${scale.second};  productId = ${this.productId};
          //   deviceDataArr =
          //   [0]: ${this.deviceDataArr[0]};
          //   [1]: ${this.deviceDataArr[1]};
          //   [2]: ${this.deviceDataArr[2]};
          //   [3]: ${this.deviceDataArr[3]};
          //   [4]: ${this.deviceDataArr[4]}.`
          // }
          // this.createErrorLog(error)

          // if(this.weightScale.indexOf('.') > -1) {
          //   let weightArr = this.weightScale.split('.')
          //   this.dimensions.data.weightLB = weightArr[0]
          //
          //   console.log(this.weightScale);
          //   console.log(weightArr[0])
          //
          //   this.dimensions.data.weightOZ = parseInt((Number(this.weightScale) - Number(weightArr[0])) * 16)
          // } else {
          //   this.dimensions.data.weightLB = parseInt(this.weightScale)
          //   this.dimensions.data.weightOZ = ''
          // }
          return
        }

        switch (type) {
          case 'weightLB':
            this.dimensions.data.weightLB = event.target.value.replace('.', '').replace(',', '')
            break
          case 'weightOZ':
            this.dimensions.data.weightOZ = event.target.value.replace('.', '').replace(',', '')
            break
          default:
            break
        }
      },
    }
  }
</script>

<style scoped>

</style>
