<template>
  <div class="order-create__row custom-row">
    <div class="order-create__col custom-col custom-col--100">
<!--      <div class="order-create__section-label section-label"-->
<!--           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">-->
<!--        <div class="admin-edit" @click="editTranslate(['fba_addProductToWarehouse'])"></div>-->
<!--        {{ $t('fbm_EngravingFile.localization_value.value') }}-->
<!--      </div>-->

      <div class="mb-3 section-label"
           v-bind:class="{'admin-edit-item': isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate([
                  'fbm_EngravingFileName',
                  'calculatorEngraving_EmbroideryCalculator',
                  'calculatorEngraving_TShirtPrinting',
                  'calculatorEngraving_LeatherStamp',
                  'calculatorEngraving_PhotoPrint',
                  'calculatorEngraving_UVPrinting',
                  'calculatorEngraving_permanentVinyl',
                  'calculatorEngraving_Sticker',
                  'calculatorEngraving_HeatTransferVinyl',
                ])"></div>

        <div class="d-flex flex-wrap">
          <DefaultCheckbox
              class="mr-3 mb-2"
              v-model="FBM.data.isEngraving"
              :label="$t('fbm_EngravingFileName.localization_value.value')"
              :disabled="isDisabled('engraving')"
              @changeCheckbox="(val) => changeCustomizationType('isEngraving', val)"
          />
          <DefaultCheckbox
              v-if="!isWarehouseLodz"
              class="mr-3"
              v-model="FBM.data.Embroidery.data.isEmbroidery"
              :label="$t('calculatorEngraving_EmbroideryCalculator.localization_value.value')"
              :disabled="isDisabled('embroidery')"
              @changeCheckbox="(val) => changeCustomizationType('isEmbroidery', val)"
          />
          <DefaultCheckbox
              v-if="!isWarehouseLodz"
              class="mr-3"
              v-model="FBM.data.TShirtPrinting.data.isTShirtPrinting"
              :label="$t('calculatorEngraving_TShirtPrinting.localization_value.value')"
              :disabled="isDisabled('tShirtPrinting')"
              @changeCheckbox="(val) => changeCustomizationType('isTShirtPrinting', val)"
          />
          <DefaultCheckbox
              v-if="!isWarehouseLodz"
              class="mr-3 mb-2"
              v-model="FBM.data.LeatherStamp.data.isLeatherStamp"
              :label="$t('calculatorEngraving_LeatherStamp.localization_value.value')"
              :disabled="isDisabled('leatherStamp')"
              @changeCheckbox="(val) => changeCustomizationType('isLeatherStamp', val)"
          />
          <DefaultCheckbox
              v-if="!isWarehouseLodz"
              class="mr-3 mb-2"
              v-model="FBM.data.PhotoPrint.data.isPhotoPrint"
              :label="$t('calculatorEngraving_PhotoPrint.localization_value.value')"
              :disabled="isDisabled('photoPrint')"
              @changeCheckbox="(val) => changeCustomizationType('isPhotoPrint', val)"
          />
          <DefaultCheckbox
              v-if="!isWarehouseCambridge"
              class="mr-3 mb-2"
              v-model="FBM.data.UVPrinting.data.isUVPrinting"
              :label="$t('calculatorEngraving_UVPrinting.localization_value.value')"
              :disabled="isDisabled('uVPrinting')"
              @changeCheckbox="(val) => changeCustomizationType('isUVPrinting', val)"
          />
          <DefaultCheckbox
              v-if="isWarehouseBrooklyn"
              v-model="FBM.data.isPermanentVinyl"
              class="mr-3 mb-2"
              :label="$t('calculatorEngraving_permanentVinyl.localization_value.value')"
              :disabled="isDisabled('permanentVinyl')"
              @changeCheckbox="(val) => changeCustomizationType('isPermanentVinyl', val)"
          />

          <DefaultCheckbox
              v-if="isWarehouseBrooklyn"
              v-model="FBM.data.isSticker"
              class="mr-3 mb-2"
              :label="$t('calculatorEngraving_Sticker.localization_value.value')"
              :disabled="isDisabled('sticker')"
              @changeCheckbox="(val) => changeCustomizationType('isSticker', val)"
          />

          <DefaultCheckbox
              v-if="isWarehouseBrooklyn"
              v-model="FBM.data.isHeatTransferVinyl"
              :label="$t('calculatorEngraving_HeatTransferVinyl.localization_value.value')"
              :disabled="isDisabled('heatTransferVinyl')"
              @changeCheckbox="(val) => changeCustomizationType('isHeatTransferVinyl', val)"
          />
        </div>

      </div>

      <div class="custom-row" v-if="isAdmin && isCheckedCustomization">
        <div class="custom-col custom-col--33">
          <DefaultSelect
              :options="FBM.data.optionsCustomizationFee"
              :label="$t('fbm_EngravingCustomizationFee.localization_value.value')"
              :optionsLabel="'label'"
              :selected="FBM.data.selectedCustomizationFee"
              @change="changeCustomizationFee"
          />
        </div>
        <div class="custom-col custom-col--33">
          <DefaultInput
              v-if="otherCustomizationFee"
              :options="FBM.data.optionsCustomizationFee"
              :label="$t('fbm_EngravingOtherCustomizationFee.localization_value.value')"
              :optionsLabel="'label'"
              v-model="FBM.data.otherCustomizationFee"
          />
        </div>
      </div>


      <div class="order-create__section-label section-label" v-if="FBM.data.isEngraving">
        {{$t('fbm_EngravingFileName.localization_value.value')}}
      </div>
      <Engraving
          v-if="FBM.data.isEngraving"
          :FBM="FBM"
          :engravingCustomOptions="engravingCustomOptions"
          @changeCustomization="changeCustomization"
          @changeQuantity="changeQuantity"
      />

      <div class="order-create__section-label section-label" v-if="FBM.data.Embroidery.data.isEmbroidery">
        {{$t('calculatorEngraving_EmbroideryCalculator.localization_value.value')}}
      </div>
      <Embroidery
          v-if="FBM.data.Embroidery.data.isEmbroidery"
          :embroideryCustomOptions="embroideryCustomOptions"
          :FBM="FBM"
          @changeCustomization="changeCustomization"
      />

      <div class="order-create__section-label section-label" v-if="FBM.data.TShirtPrinting.data.isTShirtPrinting">
        {{$t('calculatorEngraving_TShirtPrinting.localization_value.value')}}
      </div>
      <TShirtPrinting
          v-if="FBM.data.TShirtPrinting.data.isTShirtPrinting"
          :FBM="FBM"
          :dtgPrintingCustomOptions="dtgPrintingCustomOptions"
          @changeCustomization="changeCustomization"
          @changeQuantity="changeQuantity"
      />

      <div class="order-create__section-label section-label" v-if="FBM.data.LeatherStamp.data.isLeatherStamp">
        {{$t('calculatorEngraving_LeatherStamp.localization_value.value')}}
      </div>
      <LeatherStamp
          v-if="FBM.data.LeatherStamp.data.isLeatherStamp"
          :FBM="FBM"
          :leatherStampCustomOptions="leatherStampCustomOptions"
          @changeCustomization="changeCustomization"
          @changeQuantity="changeQuantity"
      />

      <div class="order-create__section-label section-label" v-if="FBM.data.PhotoPrint.data.isPhotoPrint">
        {{$t('calculatorEngraving_PhotoPrint.localization_value.value')}}
      </div>
      <PhotoPrint
          v-if="FBM.data.PhotoPrint.data.isPhotoPrint"
          :FBM="FBM"
          :photoPrintSizeOptions="photoPrintSizeOptions"
          :photoPrintCustomOptions="photoPrintCustomOptions"
          @changeCustomization="changeCustomization"
          @changeQuantity="changeQuantity"
      />

      <div class="order-create__section-label section-label" v-if="FBM.data.UVPrinting.data.isUVPrinting">
        {{$t('calculatorEngraving_UVPrinting.localization_value.value')}}
      </div>
      <UVPrinting
          v-if="FBM.data.UVPrinting.data.isUVPrinting && !isWarehouseCambridge"
          :FBM="FBM"
          :UVPrintingCustomOptions="UVPrintingCustomOptions"
          @changeCustomization="changeCustomization"
          @changeQuantity="changeQuantity"
      />

      <div class="order-create__section-label section-label direction-column h-auto" v-if="FBM.data.isPermanentVinyl"
           v-bind:class="{'admin-edit-item': isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['calculatorEngraving_permanentVinyl','calculatorEngraving_permanentVinylTxt',])"></div>
        {{$t('calculatorEngraving_permanentVinyl.localization_value.value')}}

        <span class="mt-2" v-html="$t('calculatorEngraving_permanentVinylTxt.localization_value.value')"></span>
      </div>
<!--      && !isWarehouseCambridge-->
      <PermanentVinyl
          v-if="FBM.data.isPermanentVinyl"
          :FBM="FBM"
          :permanentVinylColorOptions="permanentVinylColorOptions"
          @changeQuantity="changeQuantity"
      />

      <div class="order-create__section-label section-label direction-column h-auto" v-if="FBM.data.isSticker"
           v-bind:class="{'admin-edit-item': isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['calculatorEngraving_Sticker','calculatorEngraving_StickerTxt',])"></div>
        {{$t('calculatorEngraving_Sticker.localization_value.value')}}

        <span class="mt-2" v-html="$t('calculatorEngraving_StickerTxt.localization_value.value')"></span>
      </div>
<!--      && !isWarehouseCambridge-->
      <Sticker
          v-if="FBM.data.isSticker"
          :FBM="FBM"
          @changeQuantity="changeQuantity"
      />


      <div class="order-create__section-label section-label direction-column h-auto" v-if="FBM.data.isHeatTransferVinyl"
           v-bind:class="{'admin-edit-item': isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['calculatorEngraving_HeatTransferVinyl','calculatorEngraving_HeatTransferVinylTxt',])"></div>
        {{$t('calculatorEngraving_HeatTransferVinyl.localization_value.value')}}

        <span class="mt-2" v-html="$t('calculatorEngraving_HeatTransferVinylTxt.localization_value.value')"></span>
      </div>
<!--      && !isWarehouseCambridge-->
      <HeatTransferVinyl
          v-if="FBM.data.isHeatTransferVinyl "
          :FBM="FBM"
          :heatTransferVinylColorOptions="heatTransferVinylColorOptions"
          @changeQuantity="changeQuantity"
      />

    </div>
  </div>
</template>

<script>
import DefaultCheckbox from "@/components/UI/checkboxes/DefaultCheckbox/DefaultCheckbox";
import Engraving
  from "@/components/modules/OrdersFBMModule/components/components/chunks/EngravingFile/components/Engraving";
import Embroidery
  from "@/components/modules/OrdersFBMModule/components/components/chunks/EngravingFile/components/Embroidery";
import TShirtPrinting
  from "@/components/modules/OrdersFBMModule/components/components/chunks/EngravingFile/components/TShirtPrinting";
import {productForCellMixin} from "@/mixins/productMixins/productForCellMixin";
import LeatherStamp
  from "@/components/modules/OrdersFBMModule/components/components/chunks/EngravingFile/components/LeatherStamp";
import PhotoPrint
  from "@/components/modules/OrdersFBMModule/components/components/chunks/EngravingFile/components/PhotoPrint";
import {FBMMixinsHelper} from "@/mixins/FBMMixins/FBMMixins";
import UVPrinting
  from "@/components/modules/OrdersFBMModule/components/components/chunks/EngravingFile/components/UVPrinting";
import DefaultSelect from "@/components/UI/selectiones/DefaultSelect/DefaultSelect";
import DefaultInput from "@/components/UI/inputs/DefaultInput/DefaultInput";
import PermanentVinyl
  from "@/components/modules/OrdersFBMModule/components/components/chunks/EngravingFile/components/PermanentVinyl";
import Sticker
  from "@/components/modules/OrdersFBMModule/components/components/chunks/EngravingFile/components/Sticker";
import HeatTransferVinyl
  from "@/components/modules/OrdersFBMModule/components/components/chunks/EngravingFile/components/HeatTransferVinyl";

export default {
name: "EngravingFile",
  components: {
    HeatTransferVinyl,
    Sticker,
    PermanentVinyl,
    DefaultInput,
    DefaultSelect,
    UVPrinting, PhotoPrint, LeatherStamp, TShirtPrinting, Embroidery, Engraving, DefaultCheckbox,},

  props: {
    FBM: Object,
  },

  mixins: [productForCellMixin, FBMMixinsHelper],

  computed: {
    isCheckedCustomization() {
      return this.FBM.data.isEngraving ||
      this.FBM.data.Embroidery.data.isEmbroidery ||
      this.FBM.data.TShirtPrinting.data.isTShirtPrinting ||
      this.FBM.data.LeatherStamp.data.isLeatherStamp ||
      this.FBM.data.PhotoPrint.data.isPhotoPrint ||
      this.FBM.data.UVPrinting.data.isUVPrinting ||
      this.FBM.data.isPermanentVinyl ||
      this.FBM.data.isSticker ||
      this.FBM.data.isHeatTransferVinyl
    }
  },

  watch: {
    'FBM.data.Order.data.warehouse' () {
      if(this.isWarehouseLodz) {
        this.FBM.data.Embroidery.data.isEmbroidery = false
        this.FBM.data.TShirtPrinting.data.isTShirtPrinting = false
        this.FBM.data.LeatherStamp.data.isLeatherStamp = false
        this.FBM.data.PhotoPrint.data.isPhotoPrint = false
      }

      if(!this.isWarehouseBrooklyn) {
        this.FBM.data.isPermanentVinyl = false
        this.FBM.data.isSticker = false
        this.FBM.data.isHeatTransferVinyl = false
      }

      if(this.isWarehouseCambridge) {
        this.FBM.data.UVPrinting.data.isUVPrinting = false
      }
    }
  },

  data() {
    return {

      otherCustomizationFee: false,

      engravingCustomOptions: [],
      embroideryCustomOptions: [],
      dtgPrintingCustomOptions: [],
      leatherStampCustomOptions: [],
      photoPrintSizeOptions: [],
      photoPrintCustomOptions: [],
      UVPrintingCustomOptions: [],
      permanentVinylColorOptions: [],
      heatTransferVinylColorOptions: [],
    }
  },

  mounted() {

    if(this.FBM.data.otherCustomizationFee) {
      this.otherCustomizationFee = true
    }

    this.$store.dispatch('getTShirtCalcCreate').then(response => {


      let data = this.getRespData(response)?.customization_offers

      this.engravingCustomOptions = data?.engraving
      this.embroideryCustomOptions = data?.embroidery
      this.dtgPrintingCustomOptions = data?.dtg_printing
      this.UVPrintingCustomOptions = data?.uv_printing

      this.leatherStampCustomOptions = data?.leather_stamping
      this.photoPrintSizeOptions = data?.photo_printing_size
      this.photoPrintCustomOptions = data?.photo_printing

      this.permanentVinylColorOptions = data?.permanent_vinyl
      this.heatTransferVinylColorOptions = data?.htv

      // Object.keys(this.getRespData(response)?.colorType).map((item) => {
      //   this.colorOptions.push({
      //     value: item,
      //     translation: this.getRespData(response)?.colorType[item],
      //   })
      //   // if(index === 0) this.TShirtPrinting.data.color = this.colorOptions[0].value
      // })
      //
      // Object.keys(this.getRespData(response)?.type).map((item) => {
      //   this.qualityOptions.push({
      //     value: item,
      //     translation: this.getRespData(response)?.type[item],
      //   })
      //   // if(index === 0) this.TShirtPrinting.data.quality = this.qualities[0].value
      // })

    })
  },

  methods: {

    changeQuantity(item, quantity) {
      let productId = item?.product_id
      let productsCellArray = this.FBM.data.productsForCellArray

      if(productId) {
        productsCellArray.map((item) => {
          if(item.data.Product?.product?.id === productId) {
            item.data.Product.setProductCount(Number(quantity) || 0)
          }
        })
      }
    },

    async changeCustomization(oldItem, newItem, quantity = 1) {
      console.log(oldItem, newItem, quantity);
      let oldProductId = oldItem?.product_id
      let newProductId = newItem?.product_id
      let productsCellArray = this.FBM.data.productsForCellArray

      if(oldProductId) {
        let foundIndex = null
        productsCellArray.map((item, index) => {
          if(item.data.Product?.product?.id === oldProductId) {
            foundIndex = index
          }
        })
        if(foundIndex !== null) {
          productsCellArray.splice(foundIndex, 1)
        }
      }

      if(newProductId) {
        await this.$store.dispatch('fetchSpecialProducts', `?filter[ProductId]=${newProductId}&page=0&limit=100000`).then(response => {
          let product = this._.first(this.getRespPaginateData(response))
          if(product) {
            let index = null
            if(productsCellArray.length === 1 && productsCellArray[0].data.Product.getProductId() === '') {
              index = 0
            } else {
              index = this.FBM.addProductForCells()
            }

            productsCellArray[index].data.Product.setProduct(product)
            productsCellArray[index].data.Product.setProductCount(quantity)
            productsCellArray[index].data.Product.setProductPrice(1)
            productsCellArray[index].data.Product.setProductDescription('system product')

            if(this.isAdmin) {
              let userId = productsCellArray[index].data.Product.product?.productObject?.user_id
              this.getCellsForUser(productsCellArray[index], userId, this.FBM.data?.Order.data.warehouse?.id)
              productsCellArray[index].data.productCount = 1
            }
          }
        })
      }

      if(productsCellArray.length === 0) {
        this.FBM.addProductForCells()
      }

    },

    changeCustomizationType(type, val) {

      switch (type) {
        case 'isEngraving' :
          // this.FBM.data.TShirtPrinting.data.isTShirtPrinting = false
          // this.FBM.data.Embroidery.data.isEmbroidery = false
          // this.FBM.data.LeatherStamp.data.isLeatherStamp = false
          // this.FBM.data.PhotoPrint.data.isPhotoPrint = false
          this.FBM.data.isEngraving = val
          this.FBM.data.Engravings.map(item => {
            this.changeCustomization(item.data.customization,
                this.FBM.data.isEngraving ? item.data.customization : null)
          })

          break
        case 'isTShirtPrinting' :
          // this.FBM.data.isEngraving = false
          // this.FBM.data.Embroidery.data.isEmbroidery = false
          // this.FBM.data.LeatherStamp.data.isLeatherStamp = false
          // this.FBM.data.PhotoPrint.data.isPhotoPrint = false
          this.FBM.data.TShirtPrinting.data.isTShirtPrinting = val
          this.changeCustomization(this.FBM.data.TShirtPrinting.data.customization,
              this.FBM.data.TShirtPrinting.data.isTShirtPrinting ? this.FBM.data.TShirtPrinting.data.customization : null)
          break
        case 'isEmbroidery' :
          // this.FBM.data.isEngraving = false
          // this.FBM.data.TShirtPrinting.data.isTShirtPrinting = false
          // this.FBM.data.LeatherStamp.data.isLeatherStamp = false
          // this.FBM.data.PhotoPrint.data.isPhotoPrint = false
          this.FBM.data.Embroidery.data.isEmbroidery = val
          this.changeCustomization(this.FBM.data.Embroidery.data.customization,
              this.FBM.data.Embroidery.data.isEmbroidery ? this.FBM.data.Embroidery.data.customization : null)
          break
        case 'isLeatherStamp' :
          // this.FBM.data.isEngraving = false
          // this.FBM.data.TShirtPrinting.data.isTShirtPrinting = false
          // this.FBM.data.Embroidery.data.isEmbroidery = false
          // this.FBM.data.PhotoPrint.data.isPhotoPrint = false
          this.FBM.data.LeatherStamp.data.isLeatherStamp = val
          this.changeCustomization(this.FBM.data.LeatherStamp.data.customization,
              this.FBM.data.LeatherStamp.data.isLeatherStamp ? this.FBM.data.LeatherStamp.data.customization : null)
          break
        case 'isPhotoPrint' :
          // this.FBM.data.isEngraving = false
          // this.FBM.data.TShirtPrinting.data.isTShirtPrinting = false
          // this.FBM.data.Embroidery.data.isEmbroidery = false
          // this.FBM.data.LeatherStamp.data.isLeatherStamp = false
          this.FBM.data.PhotoPrint.data.isPhotoPrint = val
          this.changeCustomization(this.FBM.data.PhotoPrint.data.customization,
              this.FBM.data.PhotoPrint.data.isPhotoPrint ? this.FBM.data.PhotoPrint.data.customization : null)
          break
        case 'isUVPrinting' :
          this.FBM.data.UVPrinting.data.isUVPrinting = val
          this.changeCustomization(this.FBM.data.UVPrinting.data.customization,
              this.FBM.data.UVPrinting.data.isUVPrinting ? this.FBM.data.UVPrinting.data.customization : null)
          break
        case 'isPermanentVinyl' :
          this.FBM.data.isPermanentVinyl = val
          // this.FBM.data.PermanentVinyls.forEach(item => {
          //   item.data.color = null
          // })
          break
        case 'isSticker' :
          this.FBM.data.isSticker = val
          break
        case 'isHeatTransferVinyl' :
          this.FBM.data.isHeatTransferVinyl = val
          break
      }

      // this.resetNotCheckedCustomizationProduct()
    },

    // resetNotCheckedCustomizationProduct() {
    //
    //   this.changeCustomization(this.FBM.data.TShirtPrinting.data.customization,
    //       this.FBM.data.TShirtPrinting.data.isTShirtPrinting ? this.FBM.data.TShirtPrinting.data.customization : null)
    //
    //   this.changeCustomization(this.FBM.data.Embroidery.data.customization,
    //       this.FBM.data.Embroidery.data.isEmbroidery ? this.FBM.data.Embroidery.data.customization : null)
    //
    //   this.changeCustomization(this.FBM.data.engravingCustomization,
    //       this.FBM.data.isEngraving ? this.FBM.data.engravingCustomization : null)
    //
    //   this.changeCustomization(this.FBM.data.LeatherStamp.data.customization,
    //       this.FBM.data.LeatherStamp.data.isLeatherStamp ? this.FBM.data.LeatherStamp.data.customization : null)
    //
    //   this.changeCustomization(this.FBM.data.PhotoPrint.data.customization,
    //       this.FBM.data.PhotoPrint.data.isPhotoPrint ? this.FBM.data.PhotoPrint.data.customization : null)
    // },


    changeCustomizationFee(val) {
      this.FBM.data.selectedCustomizationFee = val

      if(!val) {
        this.otherCustomizationFee = false
        return
      }

      this.otherCustomizationFee = val?.label.indexOf('Other') > -1
      if(!this.otherCustomizationFee) this.FBM.data.otherCustomizationFee = ''
    },

    isDisabled(type) {
      if(this.isAdmin || this.FBM.data.Order.data.orderOBJ === null || this.FBM.data.Order.data.isDraft) return false

      if(this.FBM.data.Order.data.orderOBJ &&
          !(
              this.FBM.data.Order.data.orderOBJ?.orderFbmInstance?.engravings.length > 0 ||
              this.FBM.data.Order.data.orderOBJ?.orderFbmInstance?.embroidery?.need_embroidery ||
              this.FBM.data.Order.data.orderOBJ?.orderFbmInstance?.customization_print_u_v?.need_print_uv ||
              this.FBM.data.Order.data.orderOBJ?.orderFbmInstance?.tshirt_painting?.need_painting ||
              this.FBM.data.Order.data.orderOBJ?.orderFbmInstance?.leather_stamping?.need_leather_stampings ||
              this.FBM.data.Order.data.orderOBJ?.orderFbmInstance?.photo_print?.need_photo_print ||
              this.FBM.data.Order.data.orderOBJ?.orderFbmInstance?.permanent_vinyls.length > 0 ||
              this.FBM.data.Order.data.orderOBJ?.orderFbmInstance?.stickers.length > 0 ||
              this.FBM.data.Order.data.orderOBJ?.orderFbmInstance?.heat_transfer_vinyls.length > 0
          )
      ) {
        return false
      }

      switch (type) {
        case 'engraving':
          if(this.FBM.data.Order.data.orderOBJ?.orderFbmInstance?.engravings.length > 0) {
            return true
          }
          break;
        case 'embroidery':
          if(this.FBM.data.Order.data.orderOBJ?.orderFbmInstance?.embroidery?.need_embroidery) {
            return true
          }
          break;
        case 'tShirtPrinting':
          if(this.FBM.data.Order.data.orderOBJ?.orderFbmInstance?.tshirt_painting?.need_painting) {
            return true
          }
          break;
        case 'leatherStamp':
          if(this.FBM.data.Order.data.orderOBJ?.orderFbmInstance?.leather_stamping?.need_leather_stampings) {
            return true
          }
          break;
        case 'photoPrint':
          if(this.FBM.data.Order.data.orderOBJ?.orderFbmInstance?.photo_print?.need_photo_print) {
            return true
          }
          break;
        case 'uVPrinting':
          if(this.FBM.data.Order.data.orderOBJ?.orderFbmInstance?.customization_print_u_v?.need_print_uv) {
            return true
          }
          break;
        case 'permanentVinyl':
          if(this.FBM.data.Order.data.orderOBJ?.orderFbmInstance?.permanent_vinyls.length > 0) {
            return true
          }
          break;
        case 'sticker':
          if(this.FBM.data.Order.data.orderOBJ?.orderFbmInstance?.stickers.length > 0) {
            return true
          }
          break;
        case 'heatTransferVinyl':
          if(this.FBM.data.Order.data.orderOBJ?.orderFbmInstance?.heat_transfer_vinyls.length > 0) {
            return true
          }
          break;
      }

    }

  }
}
</script>

<style scoped>

</style>