<template>
  <div class="fragment">


    <div v-for="(heatTransferVinylItem, index) in FBM.data.HeatTransferVinyls" :key="index">
      <div class="order-create__title-horizontal"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <TitleHorizontal
            :value="' #' + (parseInt(index) + 1)"
            :rightBtn="true"
            :rightBtnType="'delete'"
            :rightBtnText="$t('common_delete.localization_value.value')"
            @rightBtnClick="FBM.removeHeatTransferVinyl(parseInt(index))"
        />
      </div>

      <HeatTransferVinylItem
          :heatTransferVinylItem="heatTransferVinylItem"
          :heatTransferVinylsCount="FBM.data.Engravings.length"
          :heatTransferVinylColorOptions="heatTransferVinylColorOptions"
          :index="index"
          @changeQuantity="(customization, val) => $emit('changeQuantity', customization, val)"
      />

    </div>

    <div class="custom-row">
      <div class="custom-col mb-0">
        <div class="order-create__product-btn add-product-btn-wrap mb-2">
          <div class="order-create__product-btn-i add-product-btn site-link"
               @click="FBM.addHeatTransferVinyl()"
          >
            <div class="admin-edit" @click="editTranslate(['common_AddNew'])"></div>
            +  {{$t('common_AddNew.localization_value.value')}} {{$t('calculatorEngraving_HeatTransferVinyl.localization_value.value')}}
          </div>
        </div>
      </div>
    </div>




  </div>
</template>

<script>
import TitleHorizontal from "@/components/coreComponents/TitleHorizontal/TitleHorizontal";
import HeatTransferVinylItem
  from "@/components/modules/OrdersFBMModule/components/components/chunks/EngravingFile/components/HeatTransferVinylItem/HeatTransferVinylItem";

export default {
  name: "HeatTransferVinyl",
  components: {HeatTransferVinylItem, TitleHorizontal, },

  props: {
    FBM: Object,
    heatTransferVinylColorOptions: Array,
  },



}
</script>

<style scoped>

</style>