<template>
  <div class="fragment">
    <div class="order-create__row custom-row">
      <div class="custom-col custom-col--33 custom-col--sm-100"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['common_comment'])"></div>
        <TextareaDefault
            :label="$t('common_comment.localization_value.value')"
            v-model="stickerItem.data.comment"
        />
      </div>
    </div>
    <div class="order-create__row custom-row">
      <div class="custom-col custom-col--16 custom-col--sm-50"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['fbm_engravingCount'])"></div>
        <DefaultInput
            :label="$t('fbm_engravingCount.localization_value.value')"
            :type="'text'"
            v-bind:class="{'ui-no-valid': stickerItem.validation.count}"
            :errorTxt="$t(`${stickerItem.validationTranslate.count}.localization_value.value`)"
            :error="stickerItem.validation.count"
            v-model="stickerItem.data.count"
            @input.native="changeStickerPrice($event, 'count')"
            @change="(val) => {calculateStickerPrice(); $emit('changeQuantity', stickerItem.data.customization, val)}"
        />
      </div>
      <div class="custom-col custom-col--16 custom-col--sm-50"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['fbm_engravingGlossy','fbm_engravingMatt'])"></div>
        <RadioDefault
            class="wfc"
            :label="$t('fbm_engravingGlossy.localization_value.value')"
            :name="'Glossy' + index"
            :value="stickerItem.data.stickerType === 'glossy'"
            @input="stickerItem.data.stickerType = 'glossy'"
        />
        <RadioDefault
            class="wfc"
            :label="$t('fbm_engravingMatt.localization_value.value')"
            :name="'Glossy' + index"
            :value="stickerItem.data.stickerType === 'matt'"
            @input="stickerItem.data.stickerType = 'matt'"
        />
      </div>
      <div class="custom-col custom-col--16 custom-col--sm-50"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['fbm_engravingStickerKissCut', 'fbm_engravingStickerPerfCut'])"></div>
        <DefaultCheckbox
            class="mb-1"
            :label="$t(`fbm_engravingStickerKissCut.localization_value.value`)"
            v-model="stickerItem.data.stickerKissCut"
        />
        <DefaultCheckbox
            :label="$t(`fbm_engravingStickerPerfCut.localization_value.value`)"
            v-bind:class="{'ui-no-valid': stickerItem.validation.stickerPerfCut}"
            :errorTxt="$t(`${stickerItem.validationTranslate.stickerPerfCut}.localization_value.value`)"
            :error="stickerItem.validation.stickerPerfCut"
            v-model="stickerItem.data.stickerPerfCut"
        />
      </div>
      <div class="custom-col custom-col--16 custom-col--sm-50"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['fbm_engravingPrice'])"></div>
        <DefaultInput
            :label="$t('fbm_engravingPrice.localization_value.value')"
            :type="'text'"
            :disabled="true"
            v-model="stickerItem.data.resultCost"
        />
      </div>
    </div>

    <div
        v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
      <div class="admin-edit" @click="editTranslate(['fbm_engravingAcceptFiles4'])"></div>
      {{ $t('fbm_engravingAcceptFiles4.localization_value.value') }}
    </div>
    <div class="order-create__row custom-row">
      <div class="custom-col position-relative"
           v-if="stickerItem.data.File.data.maxCountFiles - Object.keys(stickerItem.data.File.data.downloadFiles).length > 0">
        <DropZone class="drop-zone-bg"
                  :parentFiles="stickerItem.data.File.data.files"
                  :multiple="true"
                  :maxCount="stickerItem.data.File.data.maxCountFiles - stickerItem.data.File.data.downloadFiles.length"
                  :maxSize="stickerItem.data.File.data.maxSizeFiles"
                  @changeImg="(files, maxSize) => {changeFile(files, maxSize)}"
                  :accept="'/*'"
        />
        <div class="error-field ml-3" v-if="stickerItem.data.File.validation.files">
          {{ $t('common_fileIsRequired.localization_value.value') }}
        </div>
      </div>

      <div class="custom-col mt-3"
           v-if="Object.keys(stickerItem.data.File.data.downloadFiles).length > 0"
      >
        <div class="custom-row product-img-row">
          <div class="custom-col custom-col--16 custom-col--md-25 custom-col--sm-100"
               v-for="(item, index) in stickerItem.data.File.data.downloadFiles"
               :key="index">
            <DownloadFileBlock
                :file="item"
                @removeFile="(id) => {removeFile(id, index)}"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DefaultInput from "@/components/UI/inputs/DefaultInput/DefaultInput";
import DownloadFileBlock from "@/components/coreComponents/DownloadFileBlock/DownloadFileBlock";
import DropZone from "@/components/coreComponents/DropZone/DropZone";
import {FBMMixinsHelper} from "@/mixins/FBMMixins/FBMMixins";
import RadioDefault from "@/components/UI/radiobuttons/RadioDefault/RadioDefault";
import DefaultCheckbox from "@/components/UI/checkboxes/DefaultCheckbox/DefaultCheckbox";
import TextareaDefault from "@/components/UI/textarea/TextareaDefault/TextareaDefault";

export default {
  name: "StickerItem",
  components: {TextareaDefault, DefaultCheckbox, RadioDefault, DefaultInput, DownloadFileBlock, DropZone},

  mixins: [FBMMixinsHelper],

  props: {
    index: Number,
    FBM: Object,
    stickerItem: Object,
    permanentVinylColorOptions: Array,
  },
  
  // watch: {
  //   isWarehouseLodz() {
  //     if(this.isWarehouseLodz && this.stickerItem.data.customization) {
  //       this.changeCustomization(null)
  //     }
  //   },
  // },

  methods: {

    // changeCustomization(val) {
    //   let oldCustomization = this.stickerItem.data.customization
    //   this.stickerItem.data.customization = val;
    //   this.calculatePermanentVinylPrice();
    //   this.$emit('changeCustomization', oldCustomization, val, this.stickerItem.data.count)
    // },

    removeTenths() {
      // if(('' + this.stickerItem.data.Dimensions.data.dimensionLength).indexOf('.') > -1) this.stickerItem.data.Dimensions.data.dimensionLength = this.stickerItem.data.Dimensions.data.dimensionLength.split('.')[0]
      // if(('' + this.stickerItem.data.Dimensions.data.width).indexOf('.') > -1) this.stickerItem.data.Dimensions.data.width = this.stickerItem.data.Dimensions.data.width.split('.')[0]
      if(('' + this.stickerItem.data.count).indexOf('.') > -1) this.stickerItem.data.count = this.stickerItem.data.count.split('.')[0]
    },

    changeStickerPrice(event, type = false) {

      if(type) {
        switch (type) {
          // case 'length':
          //   this.stickerItem.data.Dimensions.data.dimensionLength = event.target.value.replace(',', '.').replace(/[^0-9.]/g, '')
          //   break
          // case 'width':
          //   this.stickerItem.data.Dimensions.data.width = event.target.value.replace(',', '.').replace(/[^0-9.]/g, '')
          //   break
          case 'count':
            this.stickerItem.data.count = event.target.value.replace(',', '.').replace(/[^0-9.]/g, '')
            break
          default:
            break
        }

        /**
         * Remove Tenths
         */
        this.removeTenths()
      }
    },

    calculateStickerPrice() {
      if(
          (!this.stickerItem.data.count || this.stickerItem.data.count.length === 0)
      ) {
        return
      }

      let data = this.stickerItem.prepareData()

      this.$store.dispatch('getStickerCalcResultInOrder', data).then(response => {
        if(this.getRespData(response)?.rate) {
          this.stickerItem.data.resultCost = parseFloat(this.getRespData(response)?.rate).toFixed(2)
        }
      })
    },

    changeFile(files, maxSizeError) {
      this.stickerItem.data.File.setFiles(files)

      this.stickerItem.data.File.setFilesMaxSizeError(maxSizeError)

      // this.calculatePermanentVinylPrice()
    },

    removeFile(id, index){

      let text = {
        title: 'common_AreYouSure',
        txt: '',
        yes: 'common_confirmDelete',
        no: 'common_confirmNo'
      }

      let confirm = () => {

        this.$store.dispatch('removeUserDocument', id).then(() => {

          this.stickerItem.data.File.removeDownloadFile(index)
          // this.FBM.data.EngravingFile.setDownloadFiles([])

          // this.calculatePermanentVinylPrice()
        })

        this.$store.commit('setConfirmDeletePopup', false)
        return true
      }

      this.deleteFunc(text, confirm)


    },
  },

}
</script>

<style scoped>

</style>